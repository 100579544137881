define('cal-culate-app/components/bootstrap-modal-dialog', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['modal', 'fade'],
		classNameBindings: ['show'],
		show: false,
		init: function init() {
			var self = this;

			this._super.apply(this, arguments);

			setTimeout(function () {
				self.changeElementStyle();
			}, 10);
		},
		changeElementStyle: (function () {
			var el = this.get('element');
			var show = this.get('show');

			if (show) {
				return el.style.display = 'block';
			}

			return el.style.display = null;
		}).observes('show'),
		didRender: function didRender() {
			var self = this;
			var el = $(this.get('element')).find('button.close');

			el.click(function () {
				self.set('show', false);
			});
		}
	});
});