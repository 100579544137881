define('cal-culate-app/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		eMail: _emberData['default'].attr('string'),
		password: _emberData['default'].attr('string'),
		fullName: _emberData['default'].attr('string'),
		isAdmin: _emberData['default'].attr('boolean'),
		isActive: _emberData['default'].attr('boolean'),
		adminCompany: _emberData['default'].belongsTo('company'),
		branch: _emberData['default'].belongsTo('branch'),
		fixedCustomer: _emberData['default'].belongsTo('customer', { inverse: 'fixedUser' })
	});
});