define("cal-culate-app/serializers/application", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = _emberData["default"].JSONSerializer.extend({
    serialize: function serialize(snapshot, options) {
      var hash = this._super.apply(this, arguments);

      if (snapshot.modelName === "customer") {
        hash = this.filterCustomerSerialization(hash);
      }

      return hash;
    },
    filterCustomerSerialization: function filterCustomerSerialization(hash) {
      delete hash.fixedUser;
      return hash;
    }
  });
});