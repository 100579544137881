define('cal-culate-app/components/meal-plan', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		mealPlanPdf: _ember['default'].inject.service('mealplanpdf'),
		mealPlanSender: _ember['default'].inject.service('mealplan-sender'),
		classNames: ['meal-plan'],
		sendSuccessAlert: false,
		actions: {
			showShopping: function showShopping() {
				this.set('showShopping', true);
			},
			showPdf: function showPdf() {
				var self = this;

				var mealPlan = this.get('mealPlan');

				mealPlan.get('parentMealPlan').then(function (parentMealPlan) {
					if (!parentMealPlan) {
						return self.get('mealPlanPdf').openPDF(mealPlan);
					}

					self.get('mealPlanPdf').openPDF(parentMealPlan);
				});
			},
			mail: function mail() {
				var self = this;

				var mealPlan = this.get('mealPlan');

				mealPlan.get('parentMealPlan').then(function (parentMealPlan) {
					if (parentMealPlan) {
						mealPlan = parentMealPlan;
					}

					self.get('mealPlanPdf').generateAndUploadPDF(mealPlan, function (err, file) {
						if (err) {
							alert(err);
						}

						self.get('mealPlanSender').sendMealPlanFile(mealPlan, file, function (err, res) {
							if (err) {
								alert(err);
							}

							self.set('sendSuccessAlert', true);
							mealPlan.set('sentAt', new Date());

							setTimeout(function () {
								self.set('sendSuccessAlert', false);
							}, 5000);
						});
					});
				});
			}
		}
	});
});