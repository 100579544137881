define('cal-culate-app/components/main-nav', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['navbar', 'navbar-light', 'navbar-expand-lg', 'bg-faded', 'fixed-top'],
		authentication: _ember['default'].inject.service(),
		showContact: false,
		actions: {
			showContact: function showContact() {
				this.set('showContact', true);
			}
		}
	});
});