define('cal-culate-app/models/recipe', ['exports', 'ember-data'], function (exports, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		name: _emberData['default'].attr('string'),
		description: _emberData['default'].attr('string'),
		forBreakfast: _emberData['default'].attr('boolean'),
		forLunch: _emberData['default'].attr('boolean'),
		forDinner: _emberData['default'].attr('boolean'),
		isSnack: _emberData['default'].attr('boolean'),
		isPwmWithWater: _emberData['default'].attr('boolean'),
		isPwmWithMilk: _emberData['default'].attr('boolean'),
		minimumMinutes: _emberData['default'].attr('number'),
		maximumMinutes: _emberData['default'].attr('number'),
		ingredients: _emberData['default'].hasMany('ingredient', { inverse: 'recipe' }),
		taste: _emberData['default'].attr('string'),
		type: _emberData['default'].attr('string')
	});
});