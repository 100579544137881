define('cal-culate-app/initializers/ember-concurrency', ['exports', 'ember-concurrency/initializers/ember-concurrency'], function (exports, _emberConcurrencyInitializersEmberConcurrency) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberConcurrencyInitializersEmberConcurrency['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberConcurrencyInitializersEmberConcurrency.initialize;
    }
  });
});