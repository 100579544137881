define('cal-culate-app/components/modal-meal', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		modalManager: _ember['default'].inject.service('modal'),
		classNames: ['modal-meal'],
		authentication: _ember['default'].inject.service(),
		actions: {
			pickAlternativeFood: function pickAlternativeFood(mealIngredient) {
				var self = this;

				this.get('modalManager').openModal({
					type: "meal-ingredient-alternatives",
					options: {
						mealIngredient: mealIngredient,
						callback: function callback(food) {
							mealIngredient.set('food', food);
							mealIngredient.save().then(function () {
								mealIngredient.get('meal.ingredients').forEach(function (item) {
									item.reload();
								});
								mealIngredient.get('meal').then(function (meal) {
									meal.reload();
								});

								_ember['default'].getOwner(self).lookup('controller:application').send("refresh");
							});
						}
					}
				});
			}
		}
	});
});