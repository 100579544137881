define('cal-culate-app/controllers/create-customer', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		store: _ember['default'].inject.service(),
		mealPlanGenerator: _ember['default'].inject.service('mealplan-generator'),
		createMealPlanBatch: function createMealPlanBatch(customer, amountOfBatchPlans) {
			var self = this;

			this.get('mealPlanGenerator').createMealPlanBatch(customer, amountOfBatchPlans, function (err, mealPlan) {
				if (err) {
					console.error(err);
					return;
				}

				self.transitionToRoute('mealplan', mealPlan);
			});
		},
		actions: {
			createMealPlan: function createMealPlan(customer) {
				var self = this;

				var companyPromise = customer.get('company');

				companyPromise.then(function (company) {
					var amountOfBatchPlans = company.get('amountOfBatchPlans');

					self.createMealPlanBatch(customer, amountOfBatchPlans);
				});
			}
		}
	});
});