define('cal-culate-app/routes/academy-post', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		contentful: _ember['default'].inject.service(),
		model: function model(params) {
			var self = this;

			return new Promise(function (resolve, reject) {
				var client = self.get('contentful').get('client');

				client.getEntries({
					content_type: 'post',
					order: 'fields.order',
					'fields.slug[in]': params.post_slug
				}).then(function (entries) {
					if (typeof entries.items[0] !== 'object') {
						return reject();
					}

					resolve(entries.items[0]);
				});
			});
		}
	});
});