define('cal-culate-app/helpers/customer-no', ['exports', 'ember'], function (exports, _ember) {
	exports.customerNo = customerNo;

	function customerNo(params) {
		var customerNo = params[0];

		if (!customerNo) {
			return "";
		}

		return customerNo.substring(0, 4) + '-' + customerNo.substring(4, 8) + '-' + customerNo.substring(8);
	}

	exports['default'] = _ember['default'].Helper.helper(customerNo);
});