define('cal-culate-app/models/branch', ['exports', 'ember-data'], function (exports, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		name: _emberData['default'].attr('string'),
		address1: _emberData['default'].attr('string'),
		address2: _emberData['default'].attr('string'),
		zip: _emberData['default'].attr('string'),
		city: _emberData['default'].attr('string'),
		telephone: _emberData['default'].attr('string'),
		eMail: _emberData['default'].attr('string'),
		planLayout: _emberData['default'].attr('object'),
		company: _emberData['default'].belongsTo('company'),
		users: _emberData['default'].hasMany('user'),
		mealPlanCount: _emberData['default'].attr('number'),
		daysDelay: _emberData['default'].attr('number'),
		billableMealPlanCount: _emberData['default'].attr('number')
	});
});