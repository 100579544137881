define('cal-culate-app/models/food', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
	exports['default'] = _emberData['default'].Model.extend({
		name: _emberData['default'].attr('string'),
		baseUnit: _emberData['default'].belongsTo('unit'),
		category: _emberData['default'].belongsTo('category', { inverse: 'foods' }),
		gramPerBaseUnit: _emberData['default'].attr('number'),
		roundBaseUnitTo: _emberData['default'].attr('number'),
		kiloCaloriesPerGram: _emberData['default'].attr('number'),
		carbsPerGram: _emberData['default'].attr('number'),
		fatsPerGram: _emberData['default'].attr('number'),
		proteinsPerGram: _emberData['default'].attr('number'),
		packageSize: _emberData['default'].attr('number'),
		notVegetarian: _emberData['default'].attr('boolean'),
		notVegan: _emberData['default'].attr('boolean'),
		notLactoseFree: _emberData['default'].attr('boolean'),
		notGlutenFree: _emberData['default'].attr('boolean'),
		notForRheumatics: _emberData['default'].attr('boolean'),
		notForGout: _emberData['default'].attr('boolean'),
		modelName: _ember['default'].computed.alias('constructor.modelName')
	});
});