define('cal-culate-app/components/lazy-var', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['lazy-var'],
		tagName: 'span',
		randString: _ember['default'].computed(function () {
			var length = Math.floor(Math.random() * 15);

			var string = "";

			for (var i = 0; i < length; i++) {
				string += "a";
			}

			return string;
		})
	});
});