define('cal-culate-app/components/meal-plan-navigator', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['meal-plan-navigator'],
		mealPlanList: _ember['default'].computed('mealPlan', function () {
			var self = this;

			return _emberData['default'].PromiseArray.create({
				promise: new _ember['default'].RSVP.Promise(function (resolve, reject) {
					var parentPromise = new Promise(function (parentResolve, parentReject) {
						var parent = self.get('mealPlan');

						parent.get('parentMealPlan').then(function () {
							if (parent.get('parentMealPlan.content')) {
								return parentResolve(parent.get('parentMealPlan.content'));
							}

							parentResolve(parent);
						});
					});

					parentPromise.then(function (parent) {
						var mealPlanList = new _ember['default'].A([]);
						mealPlanList.addObject({ idx: 1, mealPlan: parent });

						parent.get('childrenMealPlans').then(function (children) {
							if (children.get('length') == 0) {
								return resolve(mealPlanList);
							}

							var i = 2;

							children.forEach(function (child) {
								mealPlanList.addObject({ idx: i, mealPlan: child });
								i++;
							});

							resolve(mealPlanList);
						});
					});
				})
			});
		})
	});
});