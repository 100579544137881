define('cal-culate-app/components/bootstrap-modal-backdrop', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['modal-backdrop', 'fade'],
		classNameBindings: ['show'],
		show: false,
		didRender: function didRender() {
			var self = this;
			var el = this.get('element');

			el.addEventListener('click', function () {
				self.set('show', false);
			});

			self.changeElementStyle();
		},
		changeElementStyle: (function () {
			var el = this.get('element');
			var show = this.get('show');

			if (show) {
				return el.style.display = 'block';
			}

			return el.style.display = 'none';
		}).observes('show')
	});
});