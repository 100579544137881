define('cal-culate-app/models/customeractivity', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    activity: _emberData['default'].belongsTo('activity'),
    customer: _emberData['default'].belongsTo('customer'),
    hours: _emberData['default'].attr('number'),
    monday: _emberData['default'].attr('boolean'),
    tuesday: _emberData['default'].attr('boolean'),
    wednesday: _emberData['default'].attr('boolean'),
    thursday: _emberData['default'].attr('boolean'),
    friday: _emberData['default'].attr('boolean'),
    saturday: _emberData['default'].attr('boolean'),
    sunday: _emberData['default'].attr('boolean'),
    morning: _emberData['default'].attr('boolean'),
    noon: _emberData['default'].attr('boolean'),
    evening: _emberData['default'].attr('boolean'),
    isValid: Ember.computed('monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'morning', 'noon', 'evening', 'hours', function () {
      var days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      var times = ['morning', 'noon', 'evening'];

      var usedDays = false;

      for (var i = 0; i < days.length; i++) {
        if (this.get(days[i])) {
          usedDays = true;
          break;
        }
      }

      if (!usedDays) {
        return false;
      }

      var usedTimes = false;

      for (i = 0; i < times.length; i++) {
        if (this.get(times[i])) {
          usedTimes = true;
          break;
        }
      }

      if (!usedTimes) {
        return false;
      }

      return this.get('hours') > 0;
    })
  });
});