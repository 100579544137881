define('cal-culate-app/services/mealplanpdf', ['exports', 'ember', 'npm:pdfkit', 'npm:blob-stream', 'npm:async'], function (exports, _ember, _npmPdfkit, _npmBlobStream, _npmAsync) {
	exports['default'] = _ember['default'].Service.extend({
		loading: _ember['default'].inject.service(),
		upload: _ember['default'].inject.service(),
		mealPlanSettings: _ember['default'].inject.service(),
		loadedSettings: {},
		paddingLeft: 40,
		paddingRight: 40,
		paddingTop: 40,
		paddingBottom: 40,
		planColWidth: 100,
		planColPadding: 10,
		planColHeight: 50,
		docWidth: 842,
		docHeight: 595,
		shoppingListColumns: 4,
		weekDays: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
		weekDayCaptions: {
			monday: 'Montag',
			tuesday: 'Dienstag',
			wednesday: 'Mittwoch',
			thursday: 'Donnerstag',
			friday: 'Freitag',
			saturday: 'Samstag',
			sunday: 'Sonntag'
		},
		meals: ['morningSnack', 'breakfast', 'daySnack', 'lunch', 'eveningSnack', 'dinner'],
		mainMeals: ['breakfast', 'lunch', 'dinner'],
		snackMeals: ['morningSnack', 'daySnack', 'eveningSnack'],
		mealCaptions: {
			breakfast: 'Morgens',
			lunch: 'Mittags',
			dinner: 'Abends'
		},
		pageNumber: 0,
		pageOptions: {
			margin: 50,
			layout: 'landscape',
			size: 'A4',
			pageNumber: true
		},
		assetsLoaded: false,
		assetData: {},
		assetsToLoad: {},
		getDataUrlFromUrl: function getDataUrlFromUrl(url) {
			var self = this;

			return new Promise(function (resolve, reject) {
				var image = new Image();

				image.onload = function () {
					var canvas = document.createElement('canvas');
					canvas.width = this.naturalWidth;
					canvas.height = this.naturalHeight;

					canvas.getContext('2d').drawImage(this, 0, 0);

					resolve(canvas.toDataURL('image/jpg'));
				};

				image.src = url;
			});
		},
		getDataUrlFromAssetName: function getDataUrlFromAssetName(assetName) {
			var self = this;

			return this.get('assetData')[assetName];
		},
		loadAssets: function loadAssets(assetsToLoad) {
			var self = this;

			self.set('assetData', []);
			self.set('assetsLoaded', false);

			var loadTasks = [];
			var assetData = {};

			var _loop = function () {
				var assetName = key;

				loadTasks.push(function (callback) {
					if (typeof assetsToLoad[assetName] !== 'string') {
						return callback();
					}

					self.getDataUrlFromUrl(assetsToLoad[assetName]).then(function (dataUrl) {
						assetData[assetName] = dataUrl;
						callback();
					});
				});
			};

			for (var key in assetsToLoad) {
				_loop();
			}

			return new Promise(function (resolve, reject) {
				_npmAsync['default'].series(loadTasks, function () {
					self.set('assetData', assetData);
					self.set('assetsLoaded', true);
					resolve();
				});
			});
		},
		loadSettingsForMealPlan: function loadSettingsForMealPlan(mealPlan) {
			var self = this;

			this.set('loadedSettings', self.get('mealPlanSettings').get('defaults'));

			return new Promise(function (resolve, reject) {
				self.get('mealPlanSettings').getSettingsForMealPlan(mealPlan).then(function (settings) {
					self.set('loadedSettings', settings);
					resolve();
				});
			});
		},
		generatePDF: function generatePDF(mealPlan, callback) {
			var self = this;

			this.set('loading.isLoading', true);
			this.set('loading.purpose', 'Erstelle PDF Ernährungsplan...');

			this.loadSettingsForMealPlan(mealPlan).then(function () {
				var assetsToLoad = self.get('loadedSettings.assetsToLoad');

				self.loadAssets(assetsToLoad).then(function () {
					self.getNewDocument(function (doc, stream) {
						self.drawGuide(doc);
						self.drawRecipeTable(mealPlan, doc, 1);
						self.drawRecipes(mealPlan, doc);
						self.drawSnacks(mealPlan, doc);
						self.drawShoppingList(mealPlan, doc);

						var childrenPromise = mealPlan.get('childrenMealPlans');

						childrenPromise.then(function () {
							var childrenMealPlans = childrenPromise.content;

							childrenMealPlans.forEach(function (mealPlan, index) {
								self.drawRecipeTable(mealPlan, doc, index + 2);
								self.drawRecipes(mealPlan, doc);
								self.drawSnacks(mealPlan, doc);
								self.drawShoppingList(mealPlan, doc);
							});

							self.drawAttachments(mealPlan, doc);

							doc.end();
						});

						stream.on('finish', function () {
							callback(stream);
						});
					});
				});
			});
		},
		generateAndUploadPDF: function generateAndUploadPDF(mealPlan, callback) {
			var self = this;

			this.generatePDF(mealPlan, function (stream) {
				self.get('upload').upload(stream.toBlob('application/pdf'), function (err, file) {
					self.set('loading.isLoading', false);
					callback(err, file);
				});
			});
		},
		openPDF: function openPDF(mealPlan) {
			var self = this;

			this.generatePDF(mealPlan, function (stream) {
				var blob = stream.toBlob('application/pdf');

				var a = document.createElement("a");

				document.body.appendChild(a);

				a.style.display = "none";

				var url = window.URL.createObjectURL(blob);

				a.href = url;
				a.download = "Ernaehrungsplan.pdf";
				a.innerHTML = 'Download';

				a.onclick = function (e) {
					if (!navigator.userAgent.indexOf("Safari") > -1) return true;

					window.open(url);

					e.preventDefault();
					return false;
				};

				a.click();

				setTimeout(function () {
					document.body.removeChild(a);
					window.URL.revokeObjectURL(url);

					self.set('loading.isLoading', false);
				}, 1000);
			});
		},
		getMealDataByDay: function getMealDataByDay(mealPlan, day, meal) {
			return mealPlan.get('asArray')[day][meal];
		},
		drawSnacks: function drawSnacks(mealPlan, doc) {
			var snackMeals = this.get('snackMeals');
			var weekDays = this.get('weekDays');
			var weekDayCaptions = this.get('weekDayCaptions');

			var snacks = [];

			for (var i = 0; i < weekDays.length; i++) {
				for (var j = 0; j < snackMeals.length; j++) {
					var mealData = this.getMealDataByDay(mealPlan, weekDays[i], snackMeals[j]);

					if (mealData) {
						snacks.push({
							day: weekDayCaptions[weekDays[i]],
							mealData: mealData
						});
					}
				}
			}

			while (snacks.length > 4) {
				this.drawSnackPage(snacks.splice(0, 4), doc);
			}

			if (snacks.length > 0) {
				this.drawSnackPage(snacks.splice(0, 4), doc);
			}
		},
		drawSnackPage: function drawSnackPage(snacks, doc) {
			var paddingLeft = this.get('paddingLeft');
			var paddingRight = this.get('paddingRight');
			var paddingBottom = this.get('paddingBottom');
			var paddingTop = this.get('paddingTop') + 50;
			var docWidth = this.get('docWidth');
			var docHeight = this.get('docHeight');

			var columnPadding = 10;

			var columns = 4;
			var rows = 1;

			var contentWidth = docWidth - paddingLeft - paddingRight;
			var contentHeight = docHeight - paddingBottom - paddingTop;

			var columnWidth = (contentWidth - columnPadding * columns) / columns;
			var columnHeight = (contentHeight - columnPadding * columns) / rows;

			this.addPage(doc, { margin: 0, background: this.getDataUrlFromAssetName('background'), backgroundColor: this.get('loadedSettings.pageBackgroundColor') });

			doc.save();

			doc.fill(this.get('loadedSettings.headingTextColor'));

			doc.fontSize(25).text('SNACKS', paddingLeft, paddingTop - 50);

			doc.save();

			var x = paddingLeft;
			var y = paddingTop;

			for (var i = 0; i < 8; i++) {
				if (!snacks[i]) {
					break;
				}

				this.drawSnackRecipeTile(x, y, columnWidth, columnHeight, snacks[i], doc);

				if (i === columns - 1) {
					x = paddingLeft;
					y = y + columnHeight + columnPadding;
					continue;
				}

				x += columnWidth + columnPadding;
			}
		},
		drawSnackRecipeTile: function drawSnackRecipeTile(x, y, width, height, snackData, doc) {
			doc.rect(x, y, width, height);

			if (this.get('loadedSettings.snackBackgroundColor')) {
				doc.fill(this.get('loadedSettings.snackBackgroundColor'));
			}

			if (this.get('loadedSettings.snackBorderColor')) {
				doc.stroke(this.get('loadedSettings.snackBorderColor'));
			}

			doc.save();

			doc.fill(this.get('loadedSettings.snackTextColor'));

			doc.font("Helvetica-Bold");

			doc.fontSize(10).text(snackData.day, x + 5, y + 5, {
				width: width - 10
			});

			doc.fontSize(15).text(snackData.mealData.recipeName, x + 5, doc.y + 5, {
				width: width - 10
			});

			doc.save();
			doc.font("Helvetica");

			for (var i = 0; i < snackData.mealData.ingredients.length; i++) {
				var rowPos = doc.y + 12;

				if (i == 0) {
					rowPos = doc.y + 2;
				}

				var ingredient = snackData.mealData.ingredients[i];

				doc.fontSize(9).text(ingredient.amount, x + 5, rowPos, { lineBreak: false }).text(ingredient.food, x + 40, rowPos, { lineBreak: false });
			}

			doc.x = x + 5;

			doc.moveDown();

			doc.save();
			doc.font("Helvetica-Bold");

			doc.y = doc.y + 10;

			doc.fontSize(10).text("Zubereitung", {
				width: width - 10
			});

			doc.y = doc.y + 2;

			doc.save();
			doc.font("Helvetica");

			doc.fontSize(9).text(snackData.mealData.recipeDescription, {
				width: width - 10
			});

			doc.save();
		},
		drawShoppingList: function drawShoppingList(mealPlan, doc) {
			var paddingLeft = this.get('paddingLeft');
			var paddingRight = this.get('paddingRight');
			var paddingBottom = this.get('paddingBottom');
			var paddingTop = this.get('paddingTop');
			var docWidth = this.get('docWidth');
			var docHeight = this.get('docHeight');
			var shoppingListColumns = this.get('shoppingListColumns');

			var listTop = paddingTop + 50;

			var contentWidth = docWidth - paddingLeft - paddingRight;
			// var contentHeight = docHeight - listTop - paddingBottom;

			var columnWidth = contentWidth / shoppingListColumns;

			this.addPage(doc, { margin: 0, background: this.getDataUrlFromAssetName('background'), backgroundColor: this.get('loadedSettings.pageBackgroundColor') });

			doc.save();

			doc.fill(this.get('loadedSettings.headingTextColor'));

			doc.fontSize(25).text('EINKAUFSLISTE', paddingLeft, paddingTop);

			doc.save();

			var shoppingListData = mealPlan.get('asArray')['shoppingList'];

			doc.x = paddingLeft;
			doc.y = listTop;

			doc.fill(this.get('loadedSettings.shoppingListTextColor'));

			for (var categoryName in shoppingListData) {

				if (doc.y > docHeight - paddingBottom - 40) {
					doc.x += columnWidth;
					doc.y = listTop;
				}

				doc.save();
				doc.fontSize(15).text(categoryName, {
					width: columnWidth
				});

				doc.save();
				doc.fontSize(10);

				doc.moveDown();

				for (var foodName in shoppingListData[categoryName]) {
					var x = doc.x;
					var y = doc.y;

					doc.text(shoppingListData[categoryName][foodName].amount, {
						width: 30
					});

					doc.y = y;
					doc.x = x + 30;

					doc.text(shoppingListData[categoryName][foodName].baseUnit, {
						width: 30
					});

					doc.y = y;
					doc.x = x + 60;

					doc.text(foodName, {
						width: columnWidth - 60
					});

					doc.x = x;

					if (doc.y > docHeight - paddingBottom - 40) {
						doc.x += columnWidth;
						doc.y = listTop;
					}
				}

				doc.moveDown();
			}
		},
		drawRecipeTable: function drawRecipeTable(mealPlan, doc, number) {
			this.addPage(doc, { background: this.getDataUrlFromAssetName('background'), backgroundColor: this.get('loadedSettings.pageBackgroundColor') });

			this.drawHead(doc, number);
			this.drawMeals(mealPlan, doc);
			this.drawMacros(mealPlan, doc);
		},
		drawRecipes: function drawRecipes(mealPlan, doc) {
			var days = this.get('weekDays');

			for (var i = 0; i < days.length; i++) {
				this.drawDayRecipes(mealPlan, days[i], doc);
			}
		},
		drawDayRecipes: function drawDayRecipes(mealPlan, day, doc) {
			var meals = this.get('mainMeals');

			var dayCaptions = this.get('weekDayCaptions');

			this.addPage(doc, { background: this.getDataUrlFromAssetName('background'), backgroundColor: this.get('loadedSettings.pageBackgroundColor') });

			var paddingLeft = this.get('paddingLeft');
			var paddingRight = this.get('paddingRight');
			var paddingBottom = this.get('paddingBottom');
			var paddingTop = this.get('paddingTop');
			var docWidth = this.get('docWidth');
			var docHeight = this.get('docHeight');

			var recipeOffsetFromTop = 40;
			var recipePadding = 20;

			var headHeight = 30;

			var colWidth = (docWidth - paddingLeft - paddingRight - 2 * recipePadding) / 3;
			var colHeight = docHeight - paddingTop - paddingBottom - recipeOffsetFromTop - headHeight - 10;

			doc.save();

			doc.fill(this.get('loadedSettings.headingTextColor'));

			doc.fontSize(25).text('REZEPTE - ' + dayCaptions[day], paddingLeft, paddingTop);

			var mealCaptions = this.get('mealCaptions');

			for (var i = 0; i < meals.length; i++) {
				var x = paddingLeft + i * colWidth + i * recipePadding;
				// Überschrift
				doc.rect(x, paddingTop + recipeOffsetFromTop, colWidth, headHeight).fill(this.get('loadedSettings.recipeDayBackgroundColor'));
				doc.save();

				doc.fill(this.get('loadedSettings.recipeDayTextColor'));

				doc.fontSize(15).text(mealCaptions[meals[i]], x, paddingTop + recipeOffsetFromTop + 10, {
					width: colWidth,
					align: 'center'
				});

				doc.save();

				// Inhalt
				doc.rect(x, paddingTop + recipeOffsetFromTop + headHeight + 10, colWidth, colHeight);

				if (this.get('loadedSettings.recipeBackgroundColor')) {
					doc.fill(this.get('loadedSettings.recipeBackgroundColor'));
				}

				if (this.get('loadedSettings.recipeBorderColor')) {
					doc.stroke(this.get('loadedSettings.recipeBorderColor'));
				}

				doc.save();

				this.drawMeal(this.getMealDataByDay(mealPlan, day, meals[i]), x + 5, paddingTop + recipeOffsetFromTop + headHeight + 10 + 5, colWidth - 10, colHeight - 10, doc);
			}
		},
		drawMeal: function drawMeal(mealData, x, y, width, height, doc) {
			if (mealData === null) {
				return;
			}

			doc.save();

			doc.fill(this.get('loadedSettings.recipeTextColor'));

			doc.font("Helvetica-Bold");

			doc.fontSize(15).text(mealData.recipeName, x, y, {
				width: width,
				align: 'center'
			});

			doc.save();

			doc.moveDown();

			doc.fontSize(10).text("Zutaten", {
				width: width
			});

			doc.save();
			doc.font("Helvetica");

			for (var i = 0; i < mealData.ingredients.length; i++) {
				var rowPos = doc.y + 12;

				if (i == 0) {
					rowPos = doc.y + 2;
				}

				var ingredient = mealData.ingredients[i];

				doc.fontSize(9).text(ingredient.amount, x, rowPos, { lineBreak: false }).text(ingredient.food, x + 40, rowPos, { lineBreak: false });
			}

			doc.x = x;

			doc.moveDown();

			doc.save();
			doc.font("Helvetica-Bold");

			doc.y = doc.y + 10;

			doc.fontSize(10).text("Zubereitung", {
				width: width
			});

			doc.y = doc.y + 2;

			doc.save();
			doc.font("Helvetica");

			var recipeLines = mealData.recipeDescription.split(/\r?\n/);

			for (var j = 0; j < recipeLines.length; j++) {
				if (recipeLines[j] === '') {
					doc.moveDown();
				}

				doc.fontSize(9).text(recipeLines[j], {
					width: width
				});
			}

			doc.save();

			doc.x = x;

			doc.moveDown();

			doc.font("Helvetica-Bold");

			var timeString = "Zeit: " + this.getTimeStringForMinMax(mealData.recipeMinimumMinutes, mealData.recipeMaximumMinutes);

			doc.fontSize(10).text(timeString, {
				width: width
			});

			doc.save();
			doc.font("Helvetica");
		},
		getTimeStringForMinMax: function getTimeStringForMinMax(min, max) {
			if (min < 1) {
				return '< ' + max + ' Min.';
			}

			return min + ' - ' + max + ' Min.';
		},
		drawMacros: function drawMacros(mealPlan, doc) {
			var paddingLeft = this.get('paddingLeft');
			var paddingRight = this.get('paddingRight');
			// var paddingBottom = this.get('paddingBottom');

			doc.save();

			doc.fill(this.get('loadedSettings.mealPlanMacrosTextColor'));

			var targetMacroString = "Meine Zielwerte: " + Math.floor(mealPlan.get('displayKiloCalories') / 7) + "kcal | Makroverteilung " + Math.floor(mealPlan.get('displayFats') / 7) + 'g Fett / ' + Math.floor(mealPlan.get('displayProteins') / 7) + 'g Protein / ' + Math.floor(mealPlan.get('displayCarbs') / 7) + 'g Kohlenhydrate';

			doc.fontSize(10).text(targetMacroString, paddingLeft, 520, {
				width: this.get('docWidth') - paddingLeft - paddingRight,
				align: 'center'
			});
		},
		drawHead: function drawHead(doc, number) {
			var paddingTop = this.get('paddingTop');
			var paddingLeft = this.get('paddingLeft');
			var planColWidth = this.get('planColWidth');
			var planColPadding = this.get('planColPadding');

			doc.save();

			doc.fill(this.get('loadedSettings.headingTextColor'));

			var headText = 'Ernährungsplan';

			if (typeof number === 'number') {
				headText += ' Nr. ' + number;
			}

			doc.fontSize(25).text(headText, paddingLeft, paddingTop);

			var days = this.get('weekDays');
			var dayCaptions = this.get('weekDayCaptions');

			for (var i = 0; i < days.length; i++) {
				var x = paddingLeft + i * planColWidth + i * planColPadding;

				doc.rect(x, paddingTop + 40, planColWidth, 30).fill(this.get('loadedSettings.mealPlanDayBackgroundColor'));

				doc.save();

				doc.fill(this.get('loadedSettings.mealPlanDayTextColor'));

				doc.fontSize(15).text(dayCaptions[days[i]], x, paddingTop + 50, {
					width: planColWidth,
					align: 'center'
				});

				doc.save();
			}
		},
		drawMeals: function drawMeals(mealPlan, doc) {
			var paddingTop = this.get('paddingTop');
			var paddingLeft = this.get('paddingLeft');
			var planColWidth = this.get('planColWidth');
			var planColPadding = this.get('planColPadding');
			var planColHeight = this.get('planColHeight');

			var days = this.get('weekDays');
			var meals = this.get('meals');

			for (var i = 0; i < meals.length; i++) {
				for (var j = 0; j < days.length; j++) {
					var y = paddingTop + 40 + 40 + i * planColHeight + i * planColPadding;
					var x = paddingLeft + j * planColWidth + j * planColPadding;

					if (meals[i] === 'morningSnack') {
						if (mealPlan.get('customer.activityArray')[days[j]]['morning']) {
							this.drawTrainingTile(doc, x, y, planColWidth, planColHeight / 2 - 5);
						}
					}

					if (meals[i] === 'daySnack') {
						if (mealPlan.get('customer.activityArray')[days[j]]['noon']) {
							this.drawTrainingTile(doc, x, y, planColWidth, planColHeight / 2 - 5);
						}
					}

					if (meals[i] === 'eveningSnack') {
						if (mealPlan.get('customer.activityArray')[days[j]]['evening']) {
							this.drawTrainingTile(doc, x, y, planColWidth, planColHeight / 2 - 5);
						}
					}

					var mealData = this.getMealDataByDay(mealPlan, days[j], meals[i]);

					if (mealData === null) {
						continue;
					}

					if (meals[i].indexOf('Snack') !== -1) {
						this.drawSnackTile(doc, mealData, x, y + planColHeight / 2, planColWidth, planColHeight / 2);
						continue;
					}

					this.drawMealTile(doc, mealData, x, y, planColWidth, planColHeight);
				}
			}
		},
		drawTrainingTile: function drawTrainingTile(doc, x, y, width, height) {
			doc.rect(x, y, width, height).fillAndStroke(this.get('loadedSettings.mealPlanTrainingTileBackgroundColor'), this.get('loadedSettings.mealPlanTrainingTileBorderColor'));

			doc.save();

			doc.fill(this.get('loadedSettings.mealPlanTrainingTileTextColor'));

			doc.fontSize(8).text('TRAINING', x + 10, y + 8, {
				width: width - 20,
				align: 'center'
			});

			doc.save();
		},
		drawSnackTile: function drawSnackTile(doc, mealData, x, y, width, height) {
			doc.rect(x, y, width, height);

			if (this.get('loadedSettings.mealPlanSnackTileBackgroundColor')) {
				doc.fill(this.get('loadedSettings.mealPlanSnackTileBackgroundColor'));
			}

			if (this.get('loadedSettings.mealPlanSnackTileBorderColor')) {
				doc.stroke(this.get('loadedSettings.mealPlanSnackTileBorderColor'));
			}

			doc.save();

			doc.fill(this.get('loadedSettings.mealPlanSnackTileTextColor'));

			doc.fontSize(8).text(mealData.recipeName, x + 10, y + 8, {
				width: width - 20,
				align: 'center'
			});

			doc.save();
		},
		drawMealTile: function drawMealTile(doc, mealData, x, y, width, height) {
			doc.rect(x, y, width, height);

			if (this.get('loadedSettings.mealPlanTileBackgroundColor')) {
				doc.fill(this.get('loadedSettings.mealPlanTileBackgroundColor'));
			}

			if (this.get('loadedSettings.mealPlanTileBorderColor')) {
				doc.stroke(this.get('loadedSettings.mealPlanTileBorderColor'));
			}

			doc.save();

			doc.fill(this.get('loadedSettings.mealPlanTileTextColor'));

			doc.fontSize(10).text(mealData.recipeName, x + 10, y + 10, {
				width: width - 20,
				align: 'center'
			});

			doc.save();
		},
		addPage: function addPage(doc) {
			var options = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

			options = Object.assign({}, this.pageOptions, options);

			doc.addPage(options);

			if (options.backgroundColor) {
				doc.rect(0, 0, this.get('docWidth'), this.get('docHeight')).fill(this.get('loadedSettings.pageBackgroundColor'));
			}

			if (options.background) {
				doc.image(options.background, 0, 0, {
					width: this.get('docWidth'),
					height: this.get('docHeight')
				});
			}

			if (options.pageNumber) {
				this.writePageNumber(doc, options.pageNumberOptions);
			}
		},
		writePageNumber: function writePageNumber(doc) {
			var options = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

			options = Object.assign({}, { color: this.get('loadedSettings.pageNumberTextColor') }, options);

			var bottom = doc.page.margins.bottom;
			doc.page.margins.bottom = 0;

			doc.fill(options.color);
			doc.text(++this.pageNumber, 0.5 * (doc.page.width - 10), doc.page.height - 30, {
				width: 20,
				align: 'center',
				lineBreak: false
			});

			// Reset text writer position
			doc.text('', 50, 50);
			doc.page.margins.bottom = bottom;
		},
		getNewDocument: function getNewDocument(callback) {
			var self = this;

			this.pageNumber = 0;

			var coverDataUrl = this.getDataUrlFromAssetName('cover');

			var doc = new _npmPdfkit['default']({
				autoFirstPage: false
			});

			var stream = doc.pipe((0, _npmBlobStream['default'])());

			doc.font("Helvetica");

			this.addPage(doc, { background: coverDataUrl });

			callback(doc, stream);
		},
		drawGuide: function drawGuide(doc) {
			if (this.getDataUrlFromAssetName('guide_1')) {
				this.addPage(doc, { background: this.getDataUrlFromAssetName('guide_1') });
			}

			if (this.getDataUrlFromAssetName('guide_2')) {
				this.addPage(doc, { background: this.getDataUrlFromAssetName('guide_2'), pageNumberOptions: { color: '#000' } });
			}

			if (this.getDataUrlFromAssetName('guide_3')) {
				this.addPage(doc, { background: this.getDataUrlFromAssetName('guide_3') });
			}

			if (this.getDataUrlFromAssetName('guide_4')) {
				this.addPage(doc, { background: this.getDataUrlFromAssetName('guide_4'), pageNumberOptions: { color: '#000' } });
			}

			if (this.getDataUrlFromAssetName('guide_5')) {
				this.addPage(doc, { background: this.getDataUrlFromAssetName('guide_5'), pageNumberOptions: { color: '#000' } });
			}

			if (this.getDataUrlFromAssetName('guide_6')) {
				this.addPage(doc, { background: this.getDataUrlFromAssetName('guide_6'), pageNumberOptions: { color: '#000' } });
			}

			if (this.getDataUrlFromAssetName('guide_7')) {
				this.addPage(doc, { background: this.getDataUrlFromAssetName('guide_7'), pageNumberOptions: { color: '#000' } });
			}

			if (this.getDataUrlFromAssetName('guide_8')) {
				this.addPage(doc, { background: this.getDataUrlFromAssetName('guide_8'), pageNumberOptions: { color: '#000' } });
			}

			if (this.getDataUrlFromAssetName('guide_9')) {
				this.addPage(doc, { background: this.getDataUrlFromAssetName('guide_9'), pageNumberOptions: { color: '#000' } });
			}
		},
		drawAttachments: function drawAttachments(mealPlan, doc) {
			if (this.getDataUrlFromAssetName('attachment_1')) {
				this.addPage(doc, { background: this.getDataUrlFromAssetName('attachment_1') });
			}
		}
	});
});