define('cal-culate-app/components/meal-plan-summary', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		authentication: _ember['default'].inject.service(),
		store: _ember['default'].inject.service(),
		companies: _ember['default'].A([]),
		init: function init() {
			this._super.apply(this, arguments);

			this.loadCompanies();
		},
		loadCompanies: function loadCompanies() {
			if (!this.get('authentication.isAuthenticated')) {
				return;
			}

			this.set('companies', this.get('store').query('company', {}));
		}
	});
});