define('cal-culate-app/components/admin-integration', ['exports', 'ember', 'cryptojs'], function (exports, _ember, _cryptojs) {
  exports['default'] = _ember['default'].Component.extend({
    authentication: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    branches: _ember['default'].A([]),
    init: function init() {
      this._super.apply(this, arguments);

      this.loadBranches();
    },
    loadBranches: function loadBranches() {
      if (!this.get('authentication.isAuthenticated')) {
        return;
      }

      this.set('branches', this.get('store').query('branch', {}));
    },
    actions: {
      changeBranch: function changeBranch(branch) {
        this.set('integration.branch', branch);
      },
      save: function save() {
        var self = this;

        this.get('integration').save().then(function () {
          self.set('show', false);
        }, function (res) {
          var response = res.responseJSON || JSON.parse(responseText) || {};

          if (typeof response.errors !== 'undefined') {
            alert(JSON.stringify(response.errors));
          }
        });
      },
      link: function link() {
        var integrationId = this.get('integration.id');
        var secret = this.get('integration.secret');

        var uuid = prompt("UUID eingeben");

        var signature = _cryptojs['default'].SHA256(integrationId + uuid + secret).toString();

        var url = 'https://app.cal-culate.com/api/integration-gateway?integrationId=' + integrationId + '&uuid=' + uuid + '&signature=' + signature;

        prompt("Gateway URL", url);
      }
    }
  });
});