define('cal-culate-app/components/decimal-input', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    stringValue: "",
    init: function init() {
      var self = this;

      this._super.apply(this, arguments);

      if (this.get('value') !== null) {
        this.fromValue();
      }
    },
    fromValue: function fromValue() {
      this.set('stringValue', new Intl.NumberFormat('de-DE').format(this.get('value')));
    },
    fromStringValue: function fromStringValue() {
      var stringValue = this.get('stringValue');

      var period = stringValue.indexOf('.');
      var comma = stringValue.indexOf(',');

      if (period < comma) {
        stringValue = stringValue.replace(".", "");
        stringValue = stringValue.replace(",", ".");
      }

      if (comma < period) {
        stringValue = stringValue.replace(",", "");
      }

      var value = parseFloat(stringValue);

      this.set('value', value);
      this.fromValue();
    },
    actions: {
      format: function format() {
        this.fromStringValue();
      }
    }
  });
});