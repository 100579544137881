define('cal-culate-app/routes/academy-category', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		contentful: _ember['default'].inject.service(),
		model: function model(params) {
			var self = this;

			return new Promise(function (resolve, reject) {
				var client = self.get('contentful').get('client');

				client.getEntries({
					content_type: 'category',
					'fields.slug[in]': params.category_slug,
					include: 2
				}).then(function (entries) {
					if (typeof entries.items[0] !== 'object') {
						return reject();
					}

					resolve(entries.items[0]);
				});
			});
		},
		setupController: function setupController(controller, model) {
			controller.set('model', model);
			controller.set('posts', []);

			var client = this.get('contentful').get('client');

			var id = model.sys.id;

			client.getEntries({
				content_type: 'post',
				order: 'fields.order',
				'fields.category.sys.id': id
			}).then(function (entries) {
				controller.set('posts', entries.items);

				resolve(category);
			});
		}
	});
});