define('cal-culate-app/components/tag-element', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['tag-element'],
		classNameBindings: ['additionalClasses', 'hasState', 'active'],
		additionalClasses: '',
		hasState: false,
		active: false,
		onClick: null,
		isInitialized: false,
		didRender: function didRender() {
			this._super.apply(this, arguments);

			if (this.get('isInitialized')) {
				return;
			}

			var self = this;

			this.get('element').addEventListener('click', function () {
				self.send('onClick');
			});

			this.set('isInitialized', true);
		},
		actions: {
			onClick: function onClick() {
				var handler = this.get('onClick');

				if (typeof handler === 'function') {
					handler();
				}
			}
		}
	});
});