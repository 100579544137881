define('cal-culate-app/components/meal-plan-tutorial', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    showIntro: true,
    show: false,
    init: function init() {
      this._super.apply(this, arguments);

      if (!localStorage.getItem('seenTutorial')) {
        this.set('show', true);
        localStorage.setItem('seenTutorial', 'true');
      }
    },
    actions: {
      'continue': function _continue() {
        this.set('showIntro', false);
      },
      close: function close() {
        this.set('show', false);
      }
    }
  });
});