define("cal-culate-app/controllers/settings", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Controller.extend({
		authentication: _ember["default"].inject.service(),
		colorFont: "#FFF",
		colorMeal: "#444",
		colorMealBackground: "#FFF",
		colorMealBorder: "#444",
		colorTraining: "#444",
		colorTrainingBackground: "#FFF",
		colorTrainingBorder: "#444",
		alignLogo: "right",
		alignments: ['left', 'right'],
		actions: {
			changeAlignment: function changeAlignment(value) {
				this.set('alignLogo', value);
			},
			changeBranch: function changeBranch(branch) {
				console.log(branch);
			}
		}
	});
});