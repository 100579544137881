define('cal-culate-app/components/main-menu', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		initialized: false,
		authentication: _ember['default'].inject.service(),
		store: _ember['default'].inject.service(),
		classNames: ['main-menu'],
		classNameBindings: ['initialized'],
		customerQuery: "",
		didRender: function didRender() {
			var self = this;

			this._super.apply(this, arguments);

			setTimeout(function () {
				self.set('initialized', true);
			}, 10);
		},
		init: function init() {
			this._super.apply(this, arguments);

			this.updateCustomers();
		},
		showSearch: false,
		actions: {
			showSearch: function showSearch() {
				this.set('showSearch', true);
			}
		},
		customers: _ember['default'].A([]),
		updateCustomers: (function () {
			if (!this.get('authentication.isAuthenticated')) {
				return;
			}

			if (this.get('customerQuery').length < 3) {
				return;
			}

			this.set('customers', this.get('store').query('customer', { search: this.get('customerQuery') }));
		}).observes('authentication.isAuthenticated', 'authentication.currentUser', 'customerQuery')
	});
});