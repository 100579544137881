define('cal-culate-app/transforms/object', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      var object;

      try {
        object = JSON.parse(serialized);
      } catch (ex) {
        object = null;
      }

      return object;
    },

    serialize: function serialize(deserialized) {
      var string;

      try {
        string = JSON.stringify(deserialized);
      } catch (ex) {
        string = null;
      }

      return string;
    }
  });
});