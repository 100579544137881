define('cal-culate-app/components/food-list-category', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		showList: false,
		foodExceptions: new _ember['default'].A([]),
		actions: {
			remove: function remove() {
				var callback = this.get('onRemove');

				if (typeof callback !== 'function') {
					return;
				}

				callback(this.get('category'));
			},
			showList: function showList() {
				var self = this;

				var el = this.get('element');

				this.set('showList', true);

				if (typeof this.get('onShow') === 'function') {
					this.get('onShow')();
				}

				var closeHandler = function closeHandler(event) {
					if (event.target === el || el.contains(event.target)) {
						return;
					}

					self.set('showList', false);
				};

				document.body.addEventListener('click', closeHandler);
				document.body.addEventListener('click', function (event) {
					if (event.target === el || el.contains(event.target)) {
						return;
					}

					document.body.removeEventListener('click', closeHandler);
				});
			},
			toggleException: function toggleException(food) {
				var exceptions = this.get('foodExceptions');

				if (!exceptions) {
					exceptions = this.set('foodExceptions', new _ember['default'].A([]));
				}

				if (exceptions.includes(food)) {
					return exceptions.removeObject(food);
				}

				exceptions.addObject(food);
			}
		}
	});
});