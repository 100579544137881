define('cal-culate-app/models/meal', ['exports', 'ember-data'], function (exports, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		recipe: _emberData['default'].belongsTo('recipe'),
		ingredients: _emberData['default'].hasMany('mealingredient'),
		fats: _emberData['default'].attr('number'),
		proteins: _emberData['default'].attr('number'),
		carbs: _emberData['default'].attr('number'),
		targetFats: _emberData['default'].attr('number'),
		targetProteins: _emberData['default'].attr('number'),
		targetCarbs: _emberData['default'].attr('number'),
		name: _emberData['default'].attr('string'),
		description: _emberData['default'].attr('string')
	});
});