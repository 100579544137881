define('cal-culate-app/services/meal-plan-settings', ['exports', 'ember'], function (exports, _ember) {

	var sportboxStyle = {
		assetsToLoad: {
			'guide_1': '/assets/images/ep_guide_1.jpg',
			'guide_2': '/assets/images/ep_guide_2.jpg',
			'guide_3': '/assets/images/ep_guide_3.jpg',
			'guide_4': '/assets/images/ep_guide_4.jpg',
			'guide_5': '/assets/images/ep_guide_5.jpg',
			'guide_6': '/assets/images/ep_guide_6.jpg',
			'guide_7': '/assets/images/ep_guide_7.jpg',
			'cover': '/assets/images/ep/whitelabel/ep_deckblatt.jpg',
			'background': '/assets/images/ep/sportbox/ep_background.jpg',
			'attachment_1': false
		}
	};

	var benefitStyle = {
		assetsToLoad: {
			'guide_1': '/assets/images/ep_guide_1.jpg',
			'guide_2': '/assets/images/ep_guide_2.jpg',
			'guide_3': '/assets/images/ep_guide_3.jpg',
			'guide_4': '/assets/images/ep_guide_4.jpg',
			'guide_5': '/assets/images/ep_guide_5.jpg',
			'guide_6': '/assets/images/ep_guide_6.jpg',
			'guide_7': '/assets/images/ep_guide_7.jpg',
			'cover': '/assets/images/ep_deckblatt.jpg',
			'background': '/assets/images/ep_background.jpg',
			'attachment_1': false
		}
	};

	var konkaStyle = {
		assetsToLoad: {
			'guide_1': '/assets/images/ep/konka/guide_2.jpg',
			'guide_2': '/assets/images/ep/konka/guide_3.jpg',
			'guide_3': false,
			'guide_4': false,
			'guide_5': false,
			'guide_6': false,
			'guide_7': false,
			'cover': '/assets/images/ep/konka/guide_1.jpg',
			'background': '/assets/images/ep/konka/hintergrund.jpg',
			'attachment_1': false
		},
		pageBackgroundColor: '#FFFFFF',
		headingTextColor: '#000000',
		snackBackgroundColor: '#FFFFFF',
		snackTextColor: '#000000',
		shoppingListTextColor: '#000000',
		recipeDayBackgroundColor: '#474647',
		recipeDayTextColor: '#FFFFFF',
		recipeBackgroundColor: '#FFFFFF',
		recipeTextColor: '#000000',
		mealPlanDayBackgroundColor: '#474647',
		mealPlanDayTextColor: '#FFFFFF',
		mealPlanTileBackgroundColor: '#FFFFFF',
		mealPlanTileBorderColor: '#474647',
		mealPlanTileTextColor: '#000000',
		mealPlanSnackTileBackgroundColor: '#FFFFFF',
		mealPlanSnackTileBorderColor: '#474647',
		mealPlanSnackTileTextColor: '#000000',
		mealPlanTrainingTileBackgroundColor: '#474647',
		mealPlanTrainingTileBorderColor: '#474647',
		mealPlanTrainingTileTextColor: '#FFFFFF',
		pageNumberTextColor: '#000000',
		mealPlanMacrosTextColor: '#000000'
	};

	var bodyStreetStyle = {
		assetsToLoad: {
			'guide_1': '/assets/images/ep_guide_1.jpg',
			'guide_2': '/assets/images/ep_guide_2.jpg',
			'guide_3': '/assets/images/ep_guide_3.jpg',
			'guide_4': '/assets/images/ep_guide_4.jpg',
			'guide_5': '/assets/images/ep_guide_5.jpg',
			'guide_6': '/assets/images/ep_guide_6.jpg',
			'guide_7': '/assets/images/ep_guide_7.jpg',
			'cover': '/assets/images/ep/bodystreet/cover.jpg',
			'background': '/assets/images/ep/bodystreet/ep_background.png',
			'attachment_1': '/assets/images/ep/bodystreet/outro.jpg'
		},
		mealPlanDayBackgroundColor: '#00121e',
		headingTextColor: '#e30613',
		mealPlanDayTextColor: '#ffffff',
		mealPlanTileBorderColor: '#ffffff',
		mealPlanTileBackgroundColor: '#ffffff',
		mealPlanTileTextColor: '#00121e',
		mealPlanSnackTileTextColor: '#00121e',
		mealPlanSnackTileBackgroundColor: '#ffffff',
		mealPlanSnackTileBorderColor: '#ffffff',
		mealPlanTrainingTileTextColor: '#ffffff',
		mealPlanTrainingTileBorderColor: '#00121e',
		mealPlanTrainingTileBackgroundColor: '#00121e',
		mealPlanMacrosTextColor: '#00121e',
		pageNumberTextColor: '#00121e',
		recipeDayTextColor: '#ffffff',
		recipeDayBackgroundColor: '#00121e',
		recipeBackgroundColor: '#ffffff',
		recipeTextColor: '#00121e',
		snackTextColor: '#00121e',
		snackBackgroundColor: '#ffffff',
		shoppingListTextColor: '#00121e',
		pageBackgroundColor: '#acd0e2'
	};

	var gymlabStyle = {
		assetsToLoad: {
			'guide_1': false,
			'guide_2': false,
			'guide_3': false,
			'guide_4': false,
			'guide_5': false,
			'guide_6': false,
			'guide_7': false,
			'cover': '/assets/images/ep/gymlab/cover.jpeg',
			'background': '/assets/images/ep/gymlab/ep_background.jpg',
			'attachment_1': false
		}
	};

	var sporteveStyle = {
		assetsToLoad: {
			'guide_1': '/assets/images/ep/sporteve/guide/1.jpg',
			'guide_2': '/assets/images/ep/sporteve/guide/2.jpg',
			'guide_3': '/assets/images/ep/sporteve/guide/3.jpg',
			'guide_4': '/assets/images/ep/sporteve/guide/4.jpg',
			'guide_5': '/assets/images/ep/sporteve/guide/5.jpg',
			'guide_6': '/assets/images/ep/sporteve/guide/6.jpg',
			'guide_7': '/assets/images/ep/sporteve/guide/7.jpg',
			'cover': '/assets/images/ep/sporteve/cover.jpg',
			'background': false,
			'attachment_1': false
		},
		pageBackgroundColor: '#87d2d2',
		headingTextColor: '#00234b',
		recipeDayBackgroundColor: '#a8a8a8',
		mealPlanDayBackgroundColor: '#a8a8a8',
		mealPlanTrainingTileBackgroundColor: '#00234b',
		mealPlanTrainingTileBorderColor: '#00234b'
	};

	var linzenichStyle = {
		assetsToLoad: {
			'guide_1': '/assets/images/ep/linzenich/guide/1.jpg',
			'guide_2': '/assets/images/ep/linzenich/guide/2.jpg',
			'guide_3': '/assets/images/ep/linzenich/guide/3.jpg',
			'guide_4': '/assets/images/ep/linzenich/guide/4.jpg',
			'guide_5': '/assets/images/ep/linzenich/guide/5.jpg',
			'guide_6': '/assets/images/ep/linzenich/guide/6.jpg',
			'guide_7': '/assets/images/ep/linzenich/guide/7.jpg',
			'cover': '/assets/images/ep/linzenich/cover.jpg',
			'background': '/assets/images/ep/linzenich/background.jpg',
			'attachment_1': false
		},
		headingTextColor: "#000000",
		snackBackgroundColor: '#fff',
		snackTextColor: "#000000",
		shoppingListTextColor: "#000000",
		recipeDayTextColor: "#000000",
		recipeDayBackgroundColor: "#ffffff",
		recipeBackgroundColor: "#ffffff",
		recipeTextColor: "#000000",
		mealPlanDayBackgroundColor: "#ffffff",
		mealPlanDayTextColor: "#000000",
		mealPlanTileBackgroundColor: "#ffffff",
		mealPlanTileTextColor: "#000000",
		mealPlanTileBorderColor: "#ffffff",
		mealPlanSnackTileBackgroundColor: "#ffffff",
		mealPlanSnackTileTextColor: "#000000",
		mealPlanTrainingTileBackgroundColor: '#efefef',
		mealPlanTrainingTileBorderColor: '#efefef',
		mealPlanTrainingTileTextColor: '#222',
		pageNumberTextColor: "#000000",
		mealPlanMacrosTextColor: "#000000",
		mealPlanSnackTileBorderColor: '#ffffff'
	};

	var lifesportStyle = {
		assetsToLoad: {
			'guide_1': '/assets/images/ep/lifesport/guide/1.jpg',
			'guide_2': '/assets/images/ep/lifesport/guide/2.jpg',
			'guide_3': '/assets/images/ep/lifesport/guide/3.jpg',
			'guide_4': '/assets/images/ep/lifesport/guide/4.jpg',
			'guide_5': '/assets/images/ep/lifesport/guide/5.jpg',
			'guide_6': '/assets/images/ep/lifesport/guide/6.jpg',
			'guide_7': '/assets/images/ep/lifesport/guide/7.jpg',
			'cover': '/assets/images/ep/lifesport/cover.jpg',
			'background': '/assets/images/ep/lifesport/background.jpg',
			'attachment_1': false
		},
		headingTextColor: "#000000",
		snackBackgroundColor: '#fff',
		snackTextColor: "#000000",
		shoppingListTextColor: "#000000",
		recipeDayTextColor: "#000000",
		recipeDayBackgroundColor: "#ffffff",
		recipeBackgroundColor: "#ffffff",
		recipeTextColor: "#000000",
		mealPlanDayBackgroundColor: "#ffffff",
		mealPlanDayTextColor: "#000000",
		mealPlanTileBackgroundColor: "#ffffff",
		mealPlanTileTextColor: "#000000",
		mealPlanTileBorderColor: "#ffffff",
		mealPlanSnackTileBackgroundColor: "#ffffff",
		mealPlanSnackTileTextColor: "#000000",
		mealPlanTrainingTileBackgroundColor: '#efefef',
		mealPlanTrainingTileBorderColor: '#efefef',
		mealPlanTrainingTileTextColor: '#222',
		pageNumberTextColor: "#000000",
		mealPlanMacrosTextColor: "#000000",
		mealPlanSnackTileBorderColor: '#ffffff'
	};

	var bluezoneStyle = {
		assetsToLoad: {
			'guide_1': '/assets/images/ep/bluezone/guide_1.jpg',
			'guide_2': '/assets/images/ep/bluezone/guide_2.jpg',
			'guide_3': '/assets/images/ep/bluezone/guide_3.jpg',
			'guide_4': '/assets/images/ep/bluezone/guide_4.jpg',
			'guide_5': '/assets/images/ep/bluezone/guide_5.jpg',
			'guide_6': '/assets/images/ep/bluezone/guide_6.jpg',
			'guide_7': '/assets/images/ep/bluezone/guide_7.jpg',
			'guide_8': '/assets/images/ep/bluezone/guide_8.jpg',
			'guide_9': '/assets/images/ep/bluezone/guide_9.jpg',
			'cover': '/assets/images/ep/bluezone/cover.jpg',
			'background': '/assets/images/ep/bluezone/background.jpg',
			'attachment_1': false
		},
		headingTextColor: "#0054a2",
		snackBackgroundColor: false,
		snackTextColor: "#000",
		snackBorderColor: "#76bbe8",
		shoppingListTextColor: "#000000",
		recipeDayTextColor: "#fff",
		recipeDayBackgroundColor: "#0054a2",
		recipeBackgroundColor: false,
		recipeBorderColor: "#76bbe8",
		recipeTextColor: "#000000",
		mealPlanDayBackgroundColor: "#008BD2",
		mealPlanDayTextColor: "#fff",
		mealPlanTileBackgroundColor: false,
		mealPlanTileTextColor: "#000000",
		mealPlanTileBorderColor: "#76bbe8",
		mealPlanSnackTileBackgroundColor: false,
		mealPlanSnackTileTextColor: "#000000",
		mealPlanSnackTileBorderColor: '#0054a2',
		mealPlanTrainingTileBackgroundColor: '#0054a2',
		mealPlanTrainingTileBorderColor: '#0054a2',
		mealPlanTrainingTileTextColor: '#fff',
		pageNumberTextColor: "#0054a2",
		mealPlanMacrosTextColor: "#008BD2"
	};

	exports['default'] = _ember['default'].Service.extend({
		defaults: {
			assetsToLoad: {
				'guide_1': '/assets/images/ep_guide_1.jpg',
				'guide_2': '/assets/images/ep_guide_2.jpg',
				'guide_3': '/assets/images/ep_guide_3.jpg',
				'guide_4': '/assets/images/ep_guide_4.jpg',
				'guide_5': '/assets/images/ep_guide_5.jpg',
				'guide_6': '/assets/images/ep_guide_6.jpg',
				'guide_7': '/assets/images/ep_guide_7.jpg',
				'guide_8': false,
				'guide_9': false,
				'cover': '/assets/images/ep/whitelabel/ep_deckblatt.jpg',
				'background': '/assets/images/ep/whitelabel/ep_background.jpg',
				'attachment_1': false
			},
			headingTextColor: '#FFF',
			snackBackgroundColor: '#eee',
			snackTextColor: '#333',
			shoppingListTextColor: '#fff',
			recipeDayBackgroundColor: '#333',
			recipeDayTextColor: '#fff',
			recipeBackgroundColor: '#eee',
			recipeTextColor: '#333',
			mealPlanDayBackgroundColor: '#333',
			mealPlanDayTextColor: '#fff',
			mealPlanTileBackgroundColor: '#eee',
			mealPlanTileBorderColor: '#eee',
			mealPlanTileTextColor: '#222',
			mealPlanSnackTileBackgroundColor: '#e0e0e0',
			mealPlanSnackTileBorderColor: '#e0e0e0',
			mealPlanSnackTileTextColor: '#222',
			mealPlanTrainingTileBackgroundColor: '#333',
			mealPlanTrainingTileBorderColor: '#333',
			mealPlanTrainingTileTextColor: '#fff',
			pageNumberTextColor: '#fff',
			mealPlanMacrosTextColor: '#fff',
			pageBackgroundColor: false
		},
		overridesByBranchName: {

			"Benefit Hamburg1": benefitStyle,
			"Benefit Osnabrück": benefitStyle,
			"Benefit Oldenburg": benefitStyle,
			"Benefit Petersfehn": benefitStyle,
			"Benefit Hannover": benefitStyle,
			"Benefit Osnabrück 2": benefitStyle,
			"Bodystreet München Kreillerstraße": bodyStreetStyle,
			"Bodystreet München Am Luitpoldpark": bodyStreetStyle,
			"Bodystreet München Glockenbach": bodyStreetStyle,
			"Bodystreet Chemnitz Bahnhofstrasse": bodyStreetStyle,
			"Bodystreet Reutlingen-Zentrum": bodyStreetStyle,
			"Bodystreet Backnang Marktplatz": bodyStreetStyle,
			"Bodystreet Waiblingen Zentrum": bodyStreetStyle,
			"Bodystreet Bremen Bahnhofsvorstadt": bodyStreetStyle,
			"Bodystreet Hildesheim Dammtor": bodyStreetStyle,
			"Bodystreet Puchheim": bodyStreetStyle,
			"Bodystreet Dachau Zentrum": bodyStreetStyle,
			"Bodystreet Fürstenfeldbruck": bodyStreetStyle,
			"Bodystreet München Laim": bodyStreetStyle,
			"Bodystreet Olching": bodyStreetStyle,
			"Bodystreet München Nymphenburg": bodyStreetStyle,
			"Bodystreet Unterschleißheim": bodyStreetStyle,
			"Bodystreet Wiesloch": bodyStreetStyle,
			"Bodystreet Mannheim Gontardplatz": bodyStreetStyle,
			"Bodystreet Wels-Linzerstrasse": bodyStreetStyle,
			"Bodystreet Wels-SCW": bodyStreetStyle,
			"Bodystreet Linz-UCC": bodyStreetStyle,
			"Bodystreet Linz-Herrenstrasse": bodyStreetStyle,
			"Bodystreet München Fürstenried West": bodyStreetStyle,
			"Bodystreet Villach-Kreuzkirche": bodyStreetStyle,
			"Bodystreet Klagenfurt St.Martin": bodyStreetStyle,
			"Bodystreet Hannover Aegidientorplatz": bodyStreetStyle,
			"Bodystreet Hannover Lister Meile": bodyStreetStyle,
			"Bodystreet Langenhagen City": bodyStreetStyle,
			"Bodystreet Bodystreet Neckarsulm Marktstrasse": bodyStreetStyle,
			"Bodystreet Salzburg Nonntal": bodyStreetStyle,
			"Bodystreet Sankt Johann im Pongau": bodyStreetStyle,
			"Bodystreet Schwerin Zentrum": bodyStreetStyle,
			"Bodystreet Duesseldorf Pempelfort": bodyStreetStyle,
			"Bodystreet Duesseldorf Benrath": bodyStreetStyle,
			"Bodystreet Duesseldorf Bilker Allee": bodyStreetStyle,
			"Bodystreet Duesseldorf Derendorf": bodyStreetStyle,
			"Bodystreet Duesseldorf Korschenbroich": bodyStreetStyle,
			"Bodystreet Schrobenhausen": bodyStreetStyle,
			"Bodystreet Neu Ulm": bodyStreetStyle,
			"Bodystreet Regensburg Kumpfmuehler Bruecke": bodyStreetStyle,
			"Bodystreet Landshut Herzoggeorgplatz": bodyStreetStyle,
			"Bodystreet Landshut Kennedyplatz": bodyStreetStyle,
			"Bodystreet Brixen": bodyStreetStyle,
			"Bodystreet Bochum Weitmar": bodyStreetStyle,
			"Bodystreet Bremen Horn-Lehe": bodyStreetStyle,
			"Bodystreet Holzkirchen": bodyStreetStyle,
			"Bodystreet Viersen Südstadt": bodyStreetStyle,
			"Bodystreet Nettetal Lobberich": bodyStreetStyle,
			"Bodystreet Erding": bodyStreetStyle,
			"Bodystreet Freising-Lerchenfeld": bodyStreetStyle,
			"Bodystreet Hamburg-Eppendorf": bodyStreetStyle,
			"Bodystreet Villingen am Romäusring": bodyStreetStyle,
			"Bodystreet Göttingen Am Lutteranger": bodyStreetStyle,
			"Bodystreet Göttingen Geismar": bodyStreetStyle,
			"Bodystreet Freiburg Basler Tor": bodyStreetStyle,
			"Bodystreet Berlin Adlershof": bodyStreetStyle,
			"Bodystreet Prenzlauer Berg": bodyStreetStyle,
			"Bodystreet Wien Waehring": bodyStreetStyle,
			"Bodystreet Münchner Freiheit": bodyStreetStyle,
			"Bodystreet München Stiglmaierplatz": bodyStreetStyle,
			"Bodystreet München Leopoldstrasse": bodyStreetStyle,
			"Bodystreet München Lehel Carré": bodyStreetStyle,
			"Bodystreet Karlsfeld": bodyStreetStyle,
			"Bodystreet München Pasing": bodyStreetStyle,
			"Bodystreet München Denning": bodyStreetStyle,
			"Bodystreet München Implerstraße": bodyStreetStyle,
			"Bodystreet München Passauerstraße": bodyStreetStyle,
			"Bodystreet München Goetheplatz": bodyStreetStyle,
			"Bodystreet Halle": bodyStreetStyle,
			"Gymlab Studio": gymlabStyle,
			"Sporteve Eppendorf": sporteveStyle,
			"Sporteve Schnelsen": sporteveStyle,
			"Sportsclub4 Lützenkirchen": linzenichStyle,
			"Sportsclub4 Olpe": linzenichStyle,
			"Sportsclub4 Lindlar": linzenichStyle,
			"Sportsclub4 Waldbröl": linzenichStyle,
			"Sportsclub4 Holweide": linzenichStyle,
			"Sportsclub4 Merheim": linzenichStyle,
			"Topfit Bergisch-Gladbach": linzenichStyle,
			"Topfit Gummersbach": linzenichStyle,
			"Ladylike Gummersbach": linzenichStyle,
			"Family Fitness Club Bergisch-Gladbach": linzenichStyle,
			"Family Fitness Club Siegburg": linzenichStyle,
			"Bodystreet Dresden Bischofsweg": bodyStreetStyle,
			"Bodystreet Dresden Blasewitzer Straße": bodyStreetStyle,
			"Bodystreet Dresden Mitte": bodyStreetStyle,
			"Bodystreet Radebeul": bodyStreetStyle,
			"Bodystreet Mering Marktplatz": bodyStreetStyle,
			"Bodystreet Augsburg Königsplatz": bodyStreetStyle,
			"Bodystreet Augsburg Göggingen": bodyStreetStyle,
			"Bodystreet Neusäß": bodyStreetStyle,
			"Bodystreet Köln Chlodwigplatz": bodyStreetStyle,
			"Bodystreet Köln Braunsfeld": bodyStreetStyle,
			"Bodystreet Köln Rudolfplatz": bodyStreetStyle,
			"Bodystreet Köln Deutz": bodyStreetStyle,
			"Bodystreet Köln Hansaplatz Mediapark": bodyStreetStyle,
			"Bodystreet Krefeld": bodyStreetStyle,
			"Bodystreet Wien Alserstraße": bodyStreetStyle,
			"Bodystreet Wien Markthalle": bodyStreetStyle,
			"Bodystreet Bad Homburg Europakreisel": bodyStreetStyle,
			"Bodystreet Frankfurt im Colosseo": bodyStreetStyle,
			"Bodystreet Frankfurt Bornheim": bodyStreetStyle,
			"Bodystreet Oldenburg Theaterwall": bodyStreetStyle,
			"Bodystreet Oldenburg Nadorst": bodyStreetStyle,
			"Bodystreet Filderstadt": bodyStreetStyle,
			"Bodystreet Gießen-Wieseck": bodyStreetStyle,
			"Bodystreet Berlin Antonplatz": bodyStreetStyle,
			"Konka Challenge": konkaStyle,
			"Sportbox Reutte": sportboxStyle,
			"Lifesport Meckenheim": lifesportStyle,
			"Bluezone Nutrition": bluezoneStyle,
			"Bodystreet Erkelenz": bodyStreetStyle,
			"Bodystreet Jülich": bodyStreetStyle,
			"Bodystreet Graz Strassganger Straße": bodyStreetStyle,
			"Bodystreet Burgdorf": bodyStreetStyle,
			"Bodystreet Wilhelmshöhe": bodyStreetStyle,
			"Bodystreet Vorderer Westen": bodyStreetStyle,
			"Bodystreet Bettenhausen": bodyStreetStyle,
			"Bodystreet Verl": bodyStreetStyle
		},
		getOverridesByBranch: function getOverridesByBranch(branch) {
			var self = this;

			var overrides = this.get('overridesByBranchName');

			return new Promise(function (resolve, reject) {
				if (typeof branch !== 'object' || branch === null || typeof branch.get !== 'function') {
					return resolve({});
				}

				if (typeof branch.then === 'undefined') {
					if (typeof overrides[branch.get('name')] !== 'undefined') {
						return resolve(overrides[branch.get('name')]);
					}

					return resolve({});
				}

				branch.then(function (branch) {
					if (typeof branch !== 'object' || branch === null || typeof branch.get !== 'function') {
						return resolve({});
					}

					if (typeof overrides[branch.get('name')] !== 'undefined') {
						return resolve(overrides[branch.get('name')]);
					}

					return resolve({});
				});
			});
		},
		getSettingsForMealPlan: function getSettingsForMealPlan(mealPlan) {
			var self = this;

			var defaults = this.get('defaults');

			return new Promise(function (resolve, reject) {
				self.getOverridesByBranch(mealPlan.get('customer.branch')).then(function (overrides) {
					var mergedSettings = Object.assign({}, defaults, overrides);

					resolve(mergedSettings);
				});
			});
		},
		getSettingForMealPlan: function getSettingForMealPlan(mealPlan, key) {
			var self = this;

			return new Promise(function (resolve, reject) {
				self.getSettingsForMealPlan(mealPlan).then(function (settings) {
					if (typeof settings[key] !== 'undefined') {
						return resolve(settings[key]);
					}

					return resolve(null);
				});
			});
		}
	});
});