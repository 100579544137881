define('cal-culate-app/helpers/task', ['exports', 'ember-concurrency/helpers/task'], function (exports, _emberConcurrencyHelpersTask) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberConcurrencyHelpersTask['default'];
    }
  });
  Object.defineProperty(exports, 'task', {
    enumerable: true,
    get: function get() {
      return _emberConcurrencyHelpersTask.task;
    }
  });
});