define('cal-culate-app/models/mealplan', ['exports', 'ember-data'], function (exports, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		customer: _emberData['default'].belongsTo('customer', { inverse: 'mealPlans' }),
		user: _emberData['default'].belongsTo('user'),
		monday: _emberData['default'].belongsTo('mealplanday'),
		tuesday: _emberData['default'].belongsTo('mealplanday'),
		wednesday: _emberData['default'].belongsTo('mealplanday'),
		thursday: _emberData['default'].belongsTo('mealplanday'),
		friday: _emberData['default'].belongsTo('mealplanday'),
		saturday: _emberData['default'].belongsTo('mealplanday'),
		sunday: _emberData['default'].belongsTo('mealplanday'),
		createdAt: _emberData['default'].attr('date'),
		sentAt: _emberData['default'].attr('date'),
		fats: _emberData['default'].attr('number'),
		proteins: _emberData['default'].attr('number'),
		carbs: _emberData['default'].attr('number'),
		kiloCalories: _emberData['default'].attr('number'),
		isArchived: _emberData['default'].attr('boolean'),
		asArray: _emberData['default'].attr(),
		targetFats: _emberData['default'].attr('number'),
		targetProteins: _emberData['default'].attr('number'),
		targetCarbs: _emberData['default'].attr('number'),
		targetKiloCalories: _emberData['default'].attr('number'),
		displayFats: Ember.computed('fats', 'targetFats', function () {
			if (this.get('showActualNutrients')) {
				return this.get('fats');
			}

			return this.get('targetFats');
		}),
		displayProteins: Ember.computed('proteins', 'targetProteins', function () {
			if (this.get('showActualNutrients')) {
				return this.get('proteins');
			}

			return this.get('targetProteins');
		}),
		displayCarbs: Ember.computed('carbs', 'targetCarbs', function () {
			if (this.get('showActualNutrients')) {
				return this.get('carbs');
			}

			return this.get('targetCarbs');
		}),
		displayKiloCalories: Ember.computed('kiloCalories', 'targetKiloCalories', function () {
			if (this.get('showActualNutrients')) {
				return this.get('kiloCalories');
			}

			return this.get('targetKiloCalories');
		}),
		parentMealPlan: _emberData['default'].belongsTo('mealplan', { inverse: 'childrenMealPlans' }),
		childrenMealPlans: _emberData['default'].hasMany('mealplan', { inverse: 'parentMealPlan' }),
		showActualNutrients: Ember.computed('fats', 'targetFats', 'proteins', 'targetProteins', 'carbs', 'targetCarbs', 'kiloCalories', 'targetKiloCalories', function () {
			var nutrients = ['fats', 'proteins', 'carbs'];

			for (var i = 0; i < nutrients.length; i++) {
				var key = nutrients[i];

				var actual = this.get(key);
				var target = this.get('target' + key.charAt(0).toUpperCase() + key.substr(1));

				if (Math.abs(target - actual) > 70) {
					return true;
				}
			}

			return false;
		})
	});
});