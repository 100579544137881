define('cal-culate-app/controllers/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    authentication: _ember['default'].inject.service(),
    exportFrom: new Date(),
    exportUntil: new Date(),
    store: _ember['default'].inject.service(),
    endpoint: _ember['default'].computed('store', function () {
      var host = this.get('store').adapterFor('application').get('host');

      return host + '/mealplans/export';
    }),
    authorizationString: _ember['default'].computed('authentication.token', function () {
      var token = this.get('authentication.token');

      if (typeof token !== 'string') {
        return {};
      }

      return 'Bearer ' + token;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.updateUserState();
    },
    didInsertElement: function didInsertElement() {
      this.updateUserState();
    },
    updateUserState: (function () {
      var self = this;

      if (!this.get('authentication').get('isAuthenticated')) {
        return;
      }

      this.get('authentication').get('currentUser').then(function (user) {
        user.get('fixedCustomer').then(function (customer) {
          if (customer !== null) {
            self.transitionToRoute('customer', customer);
          }
        });
      });
    }).observes('authentication.isAuthenticated'),
    actions: {
      doExport: function doExport() {

        var endpoint = this.get('endpoint') + '?fromDate=' + this.get('exportFrom').toISOString() + '&untilDate=' + this.get('exportUntil').toISOString();

        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
          if (this.readyState === 4 && this.status === 200) {
            var url = window.URL.createObjectURL(this.response);
            var a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = 'export.xlsx';
            document.body.appendChild(a);
            a.click();
          }
        };

        xhr.open('GET', endpoint);
        xhr.setRequestHeader('Authorization', this.get('authorizationString'));
        xhr.responseType = 'blob';
        xhr.send();
      },
      changeExportFrom: function changeExportFrom(value) {
        this.set('exportFrom', value);
      },
      changeExportUntil: function changeExportUntil(value) {
        this.set('exportUntil', value);
      }
    }
  });
});