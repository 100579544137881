define('cal-culate-app/components/bootstrap-tokenfield', ['exports', 'ember', 'npm:bootstrap-tokenfield', 'npm:jquery-autocomplete'], function (exports, _ember, _npmBootstrapTokenfield, _npmJqueryAutocomplete) {
	exports['default'] = _ember['default'].Component.extend({
		store: _ember['default'].inject.service(),
		classNames: ['bootstrap-tokenfield'],
		inputEl: null,
		labelField: "",
		queryModel: "",
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			this.inputEl = _ember['default'].$(this.get('element')).find('input.tokenfield')[0];
			this.initializeTokenfield();
		},
		initializeTokenfield: function initializeTokenfield() {
			var self = this;

			var labelField = this.get('labelField');
			var queryModel = this.get('queryModel');

			if (this.inputEl === null) {
				return;
			}

			_ember['default'].$(this.inputEl).tokenfield({
				autocomplete: {
					source: function source(request, response) {
						var filter = {};

						filter[labelField] = request;

						self.get('store').query(queryModel, {
							filter: filter
						}).then(function (food) {
							var results = [];

							food.forEach(function (item) {
								results.push({
									value: item.get(labelField),
									label: item.get(labelField)
								});
							});

							console.log(results);

							response(["Choice1", "Choice2"]);
						});
					}
				},
				showAutocompleteOnFocus: true
			});
		}
	});
});