define('cal-culate-app/controllers/integrations', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    store: _ember['default'].inject.service(),
    authentication: _ember['default'].inject.service(),
    selectedIntegration: null,
    integrations: _ember['default'].A([]),
    init: function init() {
      this._super.apply(this, arguments);

      this.loadIntegrations();
    },
    loadIntegrations: function loadIntegrations() {
      if (!this.get('authentication.isAuthenticated')) {
        return;
      }

      this.set('integrations', this.get('store').query('integration', {}));
    },
    showModal: _ember['default'].computed('selectedIntegration', function () {
      return this.get('selectedIntegration') !== null;
    }),
    actions: {
      selectIntegration: function selectIntegration(integration) {
        this.set('selectedIntegration', integration);
      },
      create: function create() {
        var integration = this.get('store').createRecord('integration', {});
        this.set('selectedIntegration', integration);
      }
    }
  });
});