define('cal-culate-app/components/auth-overlay', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    authentication: _ember['default'].inject.service(),
    routing: _ember['default'].inject.service('-routing'),
    classNames: ['auth-overlay'],
    showRegistration: false,
    classNameBindings: ['show', 'showRegistration'],
    username: '',
    password: '',
    fullName: _ember['default'].computed('firstName', 'lastName', function () {
      return this.get('firstName') + " " + this.get('lastName');
    }),
    firstName: '',
    lastName: '',
    sex: "w",
    dateOfBirth: null,
    height: 175,
    weight: 75,
    error: null,
    isLoading: false,
    show: _ember['default'].computed('authentication.isAuthenticated', function () {
      var currentRoute = this.get('routing').get('currentRouteName');

      if (["datenschutz", "impressum"].indexOf(currentRoute) !== -1) {
        return false;
      }

      return !this.get('authentication').get('isAuthenticated');
    }),
    actions: {
      login: function login(username, password) {
        var self = this;

        this.set('error', null);
        this.set('isLoading', true);

        this.get('authentication').authenticate(username, password, function (err) {
          self.set('isLoading', false);
          self.set('error', err);
        });
      },
      toggleRegistration: function toggleRegistration() {
        this.set('showRegistration', !this.get('showRegistration'));
      },
      register: function register() {
        var self = this;

        this.set('error', null);
        this.set('isLoading', true);

        this.get('authentication').register({
          "fullName": this.get('fullName'),
          "firstName": this.get('firstName'),
          "lastName": this.get('lastName'),
          "eMail": this.get('username'),
          "password": this.get('password'),
          "sex": this.get('sex'),
          "height": this.get('height'),
          "weight": this.get('weight'),
          "dateOfBirth": this.get('dateOfBirth')
        }, function (err) {
          self.set('isLoading', false);
          self.set('error', err);
        });
      }
    }
  });
});