define('cal-culate-app/components/app-foot', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    showPrivacy: false,
    classNames: ['app-foot'],
    actions: {
      showPrivacy: function showPrivacy() {
        this.set('showPrivacy', true);
      }
    }
  });
});