define('cal-culate-app/components/anamnesis-form', ['exports', 'ember', 'npm:async'], function (exports, _ember, _npmAsync) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),
		store: _ember['default'].inject.service(),
		authentication: _ember['default'].inject.service(),
		contentful: _ember['default'].inject.service(),
		classNames: ['anamnesis-form'],
		availableFood: ['Tomaten', 'Erbsen', 'Möhren', 'Koffein', 'Erdnüsse', 'Walnüsse'],
		customer: null,
		formErrors: null,
		isLoading: false,
		loadSuccess: false,
		loadError: false,
		showPALHint: false,
		blockEPCreation: false,
		compactMode: false,
		compactModeSlideIndex: 0,
		compactModeSlideCount: 0,
		showPrivacy: false,
		medicalInfo: null,
		exclusionInfo: null,
		init: function init() {
			var self = this;

			this._super.apply(this, arguments);

			if (this.get('customer') === null) {
				this.set('customer', this.get('store').createRecord('customer', {}));
			}

			this.fetchInfoTexts();
		},
		didRender: function didRender() {
			var urlParams = new URLSearchParams(window.location.search);

			if (urlParams.has('compact')) {
				this.initializeCompactMode();
				this.set('compactMode', true);
			}
		},
		fetchInfoTexts: function fetchInfoTexts() {
			var _this = this;

			var client = this.get('contentful').get('client');

			client.getEntries({
				content_type: 'hint',
				'fields.slug[in]': 'medical'
			}).then(function (entries) {
				if (typeof entries.items[0] !== 'object') {
					return;
				}

				_this.set('medicalInfo', entries.items[0]);
			});

			client.getEntries({
				content_type: 'hint',
				'fields.slug[in]': 'exclusion'
			}).then(function (entries) {
				if (typeof entries.items[0] !== 'object') {
					return;
				}

				_this.set('exclusionInfo', entries.items[0]);
			});
		},
		initializeCompactMode: function initializeCompactMode() {
			var self = this;

			var formEl = this.get('element').querySelector('form');
			var accordionContainer = formEl.parentElement.parentElement.parentElement;

			var fieldsets = formEl.querySelectorAll('fieldset');

			this.set('compactModeSlideCount', fieldsets.length);

			setTimeout(function () {
				var originalDisplay = accordionContainer.style.display;

				accordionContainer.style.display = 'block';

				self.renderCompactModeSlide();

				window.addEventListener("resize", function () {
					self.renderCompactModeSlide();
				});

				accordionContainer.style.display = originalDisplay;
			}, 250);
		},
		renderCompactModeSlide: (function () {
			var slideIndex = this.get('compactModeSlideIndex');

			if (this.get('element') === null) {
				return;
			}

			var formEl = this.get('element').querySelector('form');
			var fieldsets = formEl.querySelectorAll('fieldset');

			var padding = 50;

			var formWidth = formEl.clientWidth;

			for (var i = 0; i < fieldsets.length; i++) {
				var x = (formWidth + padding) * i - (formWidth + padding) * slideIndex;

				fieldsets[i].style.transform = "translateX(" + x + "px)";
			}
		}).observes('compactModeSlideIndex'),
		updateNutritionalType: (function () {
			var trainingTarget = this.get('customer.trainingTarget');
			var bodyType = this.get('customer.bodyType');

			var nutritionalType = 2;

			if (bodyType === 'n') {
				nutritionalType = 3;
			}

			this.set('customer.nutritionType', nutritionalType);
		}).observes('customer.bodyType', 'customer.trainingTarget'),
		resetFormErrors: function resetFormErrors() {
			this.set('formErrors', null);
		},
		handleFormErrors: function handleFormErrors(res) {
			var response = res.responseJSON || JSON.parse(responseText) || {};

			if (typeof response.errors !== 'undefined') {
				this.set('formErrors', response.errors);
			}
		},
		setSuccessTimeout: function setSuccessTimeout() {
			var self = this;

			setTimeout(function () {
				self.set('loadSuccess', false);
			}, 5000);
		},
		actions: {
			showPrivacy: function showPrivacy() {
				this.set('showPrivacy', true);
			},
			showPALHint: function showPALHint() {
				this.set('showPALHint', true);
			},
			nextSlide: function nextSlide() {
				this.set('compactModeSlideIndex', this.get('compactModeSlideIndex') + 1);
			},
			previousSlide: function previousSlide() {
				this.set('compactModeSlideIndex', this.get('compactModeSlideIndex') - 1);
			},
			addActivity: function addActivity() {
				var newActivity = this.get('store').createRecord('customeractivity', {});

				newActivity.set('customer', this.get('customer'));
			},
			save: function save() {
				var self = this;

				this.set('formErrors', []);
				this.set('loadError', false);
				this.set('loadSuccess', false);

				this.set('isLoading', true);

				this.get('customer').save().then(function () {
					var callback = self.get('callback');

					var activities = self.get('customer.activities');

					activities.then(function () {
						var tasks = [];

						activities.forEach(function (activity) {
							tasks.push(function (taskCallback) {
								activity.save().then(function () {
									taskCallback();
								});
							});
						});

						_npmAsync['default'].parallel(tasks, function () {
							self.get('customer').reload();

							if (typeof callback === 'function') {
								callback(self.get('customer'));
							}

							self.set('isLoading', false);
							self.set('loadSuccess', true);
							self.set('loadError', false);

							self.setSuccessTimeout();
						});
					});
				}, function (res) {
					self.set('isLoading', false);
					self.set('loadError', true);
					self.handleFormErrors(res);
				});
			},
			selectDateOfBirth: function selectDateOfBirth(dateOfBirth) {
				if (!(dateOfBirth instanceof Date)) {
					return;
				}

				if (dateOfBirth.getFullYear() < 1900 || dateOfBirth.getFullYear() > new Date().getFullYear()) {
					this.set('formErrors', { dateOfBirth: ["Bitte gib ein gültiges Geburtsdatum an"] });
					return;
				}

				if (typeof this.get('formErrors.dateOfBirth') !== "undefined") {
					this.set('formErrors.dateOfBirth', null);
				}

				this.set('customer.dateOfBirth', dateOfBirth);
			}
		}
	});
});