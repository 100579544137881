define('cal-culate-app/routes/mealplan', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		loading: _ember['default'].inject.service(),
		afterModel: function afterModel() {
			this.set('loading.isLoading', false);
		},
		actions: {
			refresh: function refresh() {
				this.refresh();
			}
		}
	});
});