define('cal-culate-app/helpers/get-scaled-macro-value', ['exports', 'ember'], function (exports, _ember) {
    var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

    exports.getScaledMacroValue = getScaledMacroValue;

    function getScaledMacroValue(_ref) {
        var _ref2 = _slicedToArray(_ref, 4);

        var actual = _ref2[0];
        var target = _ref2[1];
        var scaling = _ref2[2];
        var cutoff = _ref2[3];

        var sactual = Math.round(actual / scaling);
        var starget = Math.round(target / scaling);

        if (Math.abs(sactual - starget) > cutoff) {
            return '' + sactual;
        } else {
            return '' + starget;
        }
    }

    exports['default'] = _ember['default'].Helper.helper(getScaledMacroValue);
});