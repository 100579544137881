define('cal-culate-app/services/mealplan-sender', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Service.extend({
		store: _ember['default'].inject.service(),
		authentication: _ember['default'].inject.service(),
		endpoint: _ember['default'].computed('store', function () {
			var host = this.get('store').adapterFor('application').get('host');

			return host + '/mealplans';
		}),
		headers: _ember['default'].computed('authentication.token', function () {
			var token = this.get('authentication.token');

			if (typeof token !== 'string') {
				return {};
			}

			var authorisationString = 'Bearer ' + token;

			return {
				"Authorization": authorisationString
			};
		}),
		sendMealPlanFile: function sendMealPlanFile(mealPlan, file, callback) {
			var data = {
				"file": file.get('id')
			};

			var endpoint = this.get('endpoint') + "/" + mealPlan.get('id') + "/send";

			_ember['default'].$.ajax({
				type: 'POST',
				url: endpoint,
				data: JSON.stringify(data),
				headers: this.get('headers'),
				processData: false,
				contentType: 'application/json'
			}).done(function (data) {
				callback(null, data);
			});
		}
	});
});