define('cal-culate-app/components/activity-row', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['form-group', 'row'],
		store: _ember['default'].inject.service(),
		authentication: _ember['default'].inject.service(),
		initHours: false,
		availableActivities: _ember['default'].computed('authentication.isAuthenticated', function () {
			if (!this.get('authentication.isAuthenticated')) {
				return;
			}

			return this.get('store').findAll('activity');
		}),
		init: function init() {
			this._super.apply(this, arguments);
			var store = this.get('store');
			this.getOldValues();

			this.updateHours();
		},
		getOldValues: (function () {
			if (!this.get('customerActivity.hours')) {
				return;
			}
			if (this.get('initHours')) {
				return;
			}
			var totalHours = this.get('customerActivity.hours');
			var h = Math.trunc(totalHours);
			var m = totalHours % 1 * 60;
			m = Math.round(m);
			this.set('minutes', m);
			this.set('hours', h);
			this.set('initHours', true);
		}).observes('customerActivity.hours'),
		updateHours: (function () {
			if (!this.get('hours') && !this.get('minutes')) {
				// this.set('customerActivity.hours', 0);
				return;
			}
			if (!this.get('minutes')) {
				this.set('customerActivity.hours', this.get('hours'));
				return;
			}

			var h = this.get('hours');
			h = Math.round(h * 100) / 100;
			var m = this.get('minutes');
			var minutesInHour = m / 60;
			minutesInHour = Math.round(minutesInHour * 100) / 100;
			var totalHours = minutesInHour + h;
			//console.log(totalHours);
			this.set('customerActivity.hours', totalHours);
		}).observes('hours', 'minutes'),
		actions: {
			remove: function remove(activity) {
				activity.deleteRecord();
			},
			changeActivity: function changeActivity(activity) {
				this.set('customerActivity.activity', activity);
			},
			setMorning: function setMorning() {
				this.set('customerActivity.morning', true);
				this.set('customerActivity.noon', false);
				this.set('customerActivity.evening', false);
			},
			setNoon: function setNoon() {
				this.set('customerActivity.morning', false);
				this.set('customerActivity.noon', true);
				this.set('customerActivity.evening', false);
			},
			setEvening: function setEvening() {
				this.set('customerActivity.morning', false);
				this.set('customerActivity.noon', false);
				this.set('customerActivity.evening', true);
			}
		}
	});
});