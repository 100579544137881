define('cal-culate-app/helpers/concat', ['exports', 'ember'], function (exports, _ember) {
	exports.concat = concat;

	function concat(params) {
		var string = '';

		for (var i = 0; i < params.length; i++) {
			string += params[i];
		}

		return params;
	}

	exports['default'] = _ember['default'].Helper.helper(concat);
});