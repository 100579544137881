define('cal-culate-app/router', ['exports', 'ember', 'cal-culate-app/config/environment'], function (exports, _ember, _calCulateAppConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _calCulateAppConfigEnvironment['default'].locationType,
    rootURL: _calCulateAppConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('create-customer');
    this.route('settings');
    this.route('meal-plan');
    this.route('customer', { path: '/customers/:customer_id' });
    this.route('mealplan', { path: '/mealplans/:mealplan_id' });
    this.route('datenschutz');
    this.route('impressum');
    this.route('academy');
    this.route('academy-post', { path: '/academy/:post_slug' });
    this.route('academy-category', { path: '/academy/category/:category_slug' });
    this.route('branch', { path: '/branches/:branch_id' });
    this.route('integrations');
  });

  exports['default'] = Router;
});