define('cal-culate-app/components/preset-selector', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    availablePresets: _ember['default'].A([]),
    selectedPresets: _ember['default'].A([]),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('store').query('preset', {
        backgroundReload: false
      }).then(function (presets) {
        _this.set('availablePresets', presets);
      });
    },
    actions: {
      reset: function reset() {
        this.set('selectedPresets', null);
      },
      toggle: function toggle(preset) {
        if (this.get('selectedPresets').includes(preset)) {
          this.get('selectedPresets').removeObject(preset);
          return;
        }
        this.get('selectedPresets').addObject(preset);
      }
    }
  });
});