define('cal-culate-app/models/customer', ['exports', 'ember-data'], function (exports, _emberData) {
		exports['default'] = _emberData['default'].Model.extend({
				customerNo: _emberData['default'].attr('string'),
				firstName: _emberData['default'].attr('string'),
				lastName: _emberData['default'].attr('string'),
				eMail: _emberData['default'].attr('string'),
				sex: _emberData['default'].attr('string'),
				dateOfBirth: _emberData['default'].attr('date'),
				height: _emberData['default'].attr('number'),
				weight: _emberData['default'].attr('number'),
				physicalActivityLevel: _emberData['default'].attr('number'),
				trainingTarget: _emberData['default'].attr('string'),
				bodyType: _emberData['default'].attr('string'),
				hasRheumatism: _emberData['default'].attr('boolean'),
				hasDiabetes: _emberData['default'].attr('boolean'),
				hasGout: _emberData['default'].attr('boolean'),
				noLactose: _emberData['default'].attr('boolean'),
				noGluten: _emberData['default'].attr('boolean'),
				isVegan: _emberData['default'].attr('boolean'),
				isVegetarian: _emberData['default'].attr('boolean'),
				useWhey: _emberData['default'].attr('boolean'),
				wheyWithMilk: _emberData['default'].attr('boolean'),
				preferredMeal: _emberData['default'].attr('string'),
				acceptsLegal: _emberData['default'].attr('boolean'),
				user: _emberData['default'].belongsTo('user'),
				remarks: _emberData['default'].attr('string'),
				basicMetabolismConsumptionPerDay: _emberData['default'].attr('number'),
				physicalActivityLevelConsumptionPerDay: _emberData['default'].attr('number'),
				activityConsumptionPerDay: _emberData['default'].attr('number'),
				consumptionPerDay: _emberData['default'].attr('number'),
				targetConsumptionPerDay: _emberData['default'].attr('number'),
				nutritionType: _emberData['default'].attr('number'),
				fatsPerDay: _emberData['default'].attr('number'),
				proteinsPerDay: _emberData['default'].attr('number'),
				carbsPerDay: _emberData['default'].attr('number'),
				activities: _emberData['default'].hasMany('customeractivity', { inverse: 'customer' }),
				mealPlans: _emberData['default'].hasMany('mealplan', { inverse: 'customer' }),
				branch: _emberData['default'].belongsTo('branch'),
				avoidFood: _emberData['default'].hasMany('food'),
				avoidCategories: _emberData['default'].hasMany('category'),
				avoidFoodExceptions: _emberData['default'].hasMany('food'),
				presets: _emberData['default'].hasMany('preset'),
				activityArray: _emberData['default'].attr(),
				shortLunch: _emberData['default'].attr('boolean'),
				shortBreakfast: _emberData['default'].attr('boolean'),
				breakfastTaste: _emberData['default'].attr('string'),
				breakfastType: _emberData['default'].attr('string'),
				referrer: _emberData['default'].attr('string'),
				fixedUser: _emberData['default'].belongsTo('user'),
				company: Ember.computed('branch', function () {
						var self = this;

						return _emberData['default'].PromiseObject.create({
								promise: new Promise(function (resolve, reject) {
										self.get('branch').then(function (branch) {
												if (branch === null) {
														return resolve(null);
												}

												branch.get('company').then(function (company) {
														resolve(company);
												});
										});
								})
						});
				}),
				latestMealPlan: Ember.computed('mealPlans', function () {
						var self = this;

						return _emberData['default'].PromiseObject.create({
								promise: new Promise(function (resolve, reject) {
										self.get('mealPlans').then(function (mealPlans) {
												var latestMealPlan = null;

												mealPlans.forEach(function (mealPlan) {
														if (latestMealPlan === null) {
																latestMealPlan = mealPlan;
																return;
														}

														if (latestMealPlan.get('createdAt') < mealPlan.get('createdAt')) {
																latestMealPlan = mealPlan;
														}
												});

												resolve(latestMealPlan);
										});
								})
						});
				})
		});
});