define('cal-culate-app/helpers/clz32', ['exports', 'ember-math-helpers/helpers/clz32'], function (exports, _emberMathHelpersHelpersClz32) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberMathHelpersHelpersClz32['default'];
    }
  });
  Object.defineProperty(exports, 'clz32', {
    enumerable: true,
    get: function get() {
      return _emberMathHelpersHelpersClz32.clz32;
    }
  });
});