define('cal-culate-app/models/company', ['exports', 'ember-data'], function (exports, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		name: _emberData['default'].attr('string'),
		address1: _emberData['default'].attr('string'),
		address2: _emberData['default'].attr('string'),
		zip: _emberData['default'].attr('string'),
		city: _emberData['default'].attr('string'),
		telephone: _emberData['default'].attr('string'),
		eMail: _emberData['default'].attr('string'),
		amountOfBatchPlans: _emberData['default'].attr('number'),
		branches: _emberData['default'].hasMany('branch'),
		administrators: _emberData['default'].hasMany('user'),
		mealPlanCount: _emberData['default'].attr('number'),
		billableMealPlanCount: _emberData['default'].attr('number')
	});
});