define('cal-culate-app/services/authentication', ['exports', 'ember', 'ember-cli-jwt-decode'], function (exports, _ember, _emberCliJwtDecode) {
	exports['default'] = _ember['default'].Service.extend({
		store: _ember['default'].inject.service('store'),
		token: null,
		isAuthenticated: false,
		storageKey: 'jwt',
		init: function init() {
			var self = this;

			this._super.apply(this, arguments);

			this.set('token', this.getCache());

			if (this.get('token') === null) {
				this.set('isAuthenticated', false);
				return;
			}

			self.checkCachedKey();

			setInterval(function () {
				self.checkCachedKey();
			}, 1000);
		},
		currentUser: _ember['default'].computed('token', function () {
			try {
				var token = (0, _emberCliJwtDecode.jwt_decode)(this.get('token'));
			} catch (err) {
				return null;
			}

			if (typeof token.uid !== 'string') {
				return null;
			}

			return this.get('store').findRecord('user', token.uid);
		}),
		checkCachedKey: function checkCachedKey() {
			try {
				var token = (0, _emberCliJwtDecode.jwt_decode)(this.get('token'));
			} catch (err) {
				this.set('isAuthenticated', false);
				return;
			}

			var expiry = new Date(token.exp * 1000);

			if (expiry < new Date()) {
				this.set('isAuthenticated', false);
				return;
			}

			this.set('isAuthenticated', true);
		},
		getCache: function getCache() {
			return localStorage.getItem(this.get('storageKey'));
		},
		setCache: (function (value) {
			if (typeof value !== 'string') {
				value = this.get('token');
			}

			if (value === null) {
				localStorage.removeItem(this.get('storageKey'));
				return;
			}

			localStorage.setItem(this.get('storageKey'), value);
		}).observes('token'),
		authenticate: function authenticate(eMail, password, callback) {
			var self = this;

			var host = this.get('store').adapterFor('application').get('host');

			if (typeof eMail !== 'string') {
				eMail = "";
			}

			if (typeof password !== 'string') {
				password = "";
			}

			var payload = {
				eMail: eMail,
				password: password
			};

			var request = _ember['default'].$.ajax({
				url: host + '/users/login',
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				data: JSON.stringify(payload)
			});

			request.then(function (res) {
				if (typeof res.token === 'string') {
					self.set('token', res.token);
					self.set('isAuthenticated', true);
				}

				callback(null);
			}, function (error) {
				callback(error.responseJSON);

				self.set('token', null);
				self.set('isAuthenticated', false);
			});
		},
		logout: function logout() {
			this.set('token', null);
			this.set('isAuthenticated', false);
		},
		register: function register(data, callback) {
			var self = this;

			var host = this.get('store').adapterFor('application').get('host');

			if (typeof data.eMail !== 'string') {
				data.eMail = "";
			}

			if (typeof data.password !== 'string') {
				data.password = "";
			}

			var request = _ember['default'].$.ajax({
				url: host + '/users/register',
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				data: JSON.stringify(data)
			});

			request.then(function (res) {
				if (typeof res.token === 'string') {
					self.set('token', res.token);
					self.set('isAuthenticated', true);
				}

				callback(null);
			}, function (error) {
				callback(error.responseJSON);

				self.set('token', null);
				self.set('isAuthenticated', false);
			});
		}
	});
});