define("cal-culate-app/controllers/branch", ["exports", "ember", "ember-data"], function (exports, _ember, _emberData) {
  exports["default"] = _ember["default"].Controller.extend({
    store: _ember["default"].inject.service(),
    loading: false,
    mailImport: "",
    importFeedback: null,
    authentication: _ember["default"].inject.service(),
    endpoint: _ember["default"].computed('store', function () {
      var host = this.get('store').adapterFor('application').get('host');

      return host + '/branches';
    }),
    headers: _ember["default"].computed('authentication.token', function () {
      var token = this.get('authentication.token');

      if (typeof token !== 'string') {
        return {};
      }

      var authorisationString = 'Bearer ' + token;

      return {
        "Authorization": authorisationString
      };
    }),
    actions: {
      "import": function _import() {
        var _this = this;

        var mails = this.get('mailImport');

        var data = {
          "data": mails
        };

        var endpoint = this.get('endpoint') + "/" + this.get('model.id') + "/importBuyers";

        _ember["default"].$.ajax({
          type: 'POST',
          url: endpoint,
          data: JSON.stringify(data),
          headers: this.get('headers'),
          processData: false,
          contentType: 'application/json'
        }).done(function (data) {
          _this.set("importFeedback", "Import was successful");
        });
      },
      save: function save() {
        var self = this;

        self.set('loading', true);

        this.get('model').save().then(function () {
          self.set('loading', false);
        }, function (res) {
          var response = res.responseJSON || JSON.parse(responseText) || {};

          if (typeof response.errors !== 'undefined') {
            alert(JSON.stringify(response.errors));
          }
        });
      }
    }
  });
});