define('cal-culate-app/controllers/customer', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _ember['default'].Controller.extend({
    store: _ember['default'].inject.service(),
    mealPlanGenerator: _ember['default'].inject.service('mealplan-generator'),
    authentication: _ember['default'].inject.service(),
    compactMode: false,
    hideAnamnesis: false,
    blockEPCreation: false,
    showCustomerEdit: false,
    setEPBlock: (function () {
      var self = this;

      if (this.get('model') === null) {
        return self.set("blockEPCreation", false);
      }

      this.get('model.latestMealPlan').then(function (mealPlan) {
        if (mealPlan === null) {
          return self.set("blockEPCreation", false);
        }

        self.get('model.fixedUser').then(function (user) {
          if (user === null) {
            return self.set("blockEPCreation", false);
          }

          var threshold = new Date();
          if (localStorage.getItem("calc_debug_date")) {
            threshold = new Date(localStorage.getItem("calc_debug_date"));
          }

          threshold.setDate(threshold.getDate() - 9);

          if (mealPlan.get('createdAt') > threshold) {
            self.set("blockEPCreation", true);
          }
        });
      });
    }).observes('model'),
    init: function init() {
      this._super.apply(this, arguments);

      var urlParams = new URLSearchParams(window.location.search);

      if (urlParams.has('compact')) {
        this.set('compactMode', true);
      }

      this.setEPBlock();
    },
    actions: {
      createMealPlan: function createMealPlan() {
        var self = this;

        var customer = this.get('model');

        var companyPromise = customer.get('company');

        companyPromise.then(function (company) {
          var amountOfBatchPlans = 1;

          if (company !== null) {
            amountOfBatchPlans = company.get('amountOfBatchPlans');
          }

          self.get('mealPlanGenerator').createMealPlanBatch(customer, amountOfBatchPlans, function (err, mealPlan) {
            if (err) {
              return;
            }

            self.transitionToRoute('mealplan', mealPlan);
          });
        });
      },
      createMealPlanForFixedCustomer: function createMealPlanForFixedCustomer() {
        var self = this;

        var customer = this.get('model');

        var amountOfBatchPlans = 1;

        self.get('mealPlanGenerator').createMealPlanBatch(customer, amountOfBatchPlans, function (err, mealPlan) {
          if (err) {
            return;
          }

          self.transitionToRoute('mealplan', mealPlan);
        });
      },
      showCustomerEdit: function showCustomerEdit() {
        this.set("showCustomerEdit", true);
      }
    }
  });
});