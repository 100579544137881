define('cal-culate-app/controllers/mealplan', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		authentication: _ember['default'].inject.service(),

		actions: {
			toggleArchive: function toggleArchive() {
				this.get('model').set('isArchived', !this.get('model').get('isArchived'));
				this.get('model').save();
			}
		}
	});
});