define('cal-culate-app/models/file', ['exports', 'ember-data'], function (exports, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		fileName: _emberData['default'].attr('string'),
		filePrefix: _emberData['default'].attr('string'),
		title: _emberData['default'].attr('string'),
		description: _emberData['default'].attr('string'),
		size: _emberData['default'].attr('number'),
		author: _emberData['default'].belongsTo('user'),
		tagString: Ember.computed.mapBy('tags', 'name')
	});
});