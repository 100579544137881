define('cal-culate-app/components/admin-customer', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    authentication: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    branches: _ember['default'].A([]),
    init: function init() {
      this._super.apply(this, arguments);

      this.loadBranches();
    },
    loadBranches: function loadBranches() {
      if (!this.get('authentication.isAuthenticated')) {
        return;
      }

      this.set('branches', this.get('store').query('branch', {}));
    },
    actions: {
      changeBranch: function changeBranch(branch) {
        this.set('customer.branch', branch);
      },
      selectDateOfBirth: function selectDateOfBirth(dateOfBirth) {
        this.set('customer.dateOfBirth', dateOfBirth);
      },
      cancel: function cancel() {},
      save: function save() {
        var self = this;

        this.get('customer').save().then(function () {
          self.set('show', false);
        }, function (res) {
          var response = res.responseJSON || JSON.parse(responseText) || {};

          if (typeof response.errors !== 'undefined') {
            alert(JSON.stringify(response.errors));
          }
        });
      }
    }
  });
});