define('cal-culate-app/services/contentful', ['exports', 'ember', 'npm:contentful'], function (exports, _ember, _npmContentful) {
	exports['default'] = _ember['default'].Service.extend({
		client: null,
		init: function init() {
			this._super.apply(this, arguments);

			var client = _npmContentful['default'].createClient({
				space: 'kr0xrtmvn3r3',
				accessToken: 'd0e3619c46b20c3f189653ef34ea892759bffcf91bf4d7bdf8b15b2201a16fd4'
			});

			this.set('client', client);
		}
	});
});