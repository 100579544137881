define('cal-culate-app/controllers/application', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		routing: _ember['default'].inject.service('-routing'),
		isCompactMode: false,
		showFoot: _ember['default'].computed('routing.currentRouteName', function () {
			return this.get('routing').get('currentRouteName') !== 'login';
		}),
		init: function init() {
			this._super.apply(this, arguments);

			var urlParams = new URLSearchParams(window.location.search);

			if (urlParams.has('compact')) {
				this.set('isCompactMode', true);
				document.body.classList.add('compact-mode');
			}
		}
	});
});