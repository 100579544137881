define('cal-culate-app/controllers/academy', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		contentful: _ember['default'].inject.service(),
		init: function init() {
			var self = this;

			this._super.apply(this, arguments);

			var client = this.get('contentful').get('client');

			client.getEntries({
				content_type: 'category',
				order: 'fields.order',
				include: 0
			}).then(function (entries) {
				self.set('categories', entries.items);
			});
		}
	});
});