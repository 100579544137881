define('cal-culate-app/components/bootstrap-modal', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		show: false,
		init: function init() {
			var self = this;

			this._super.apply(this, arguments);

			setTimeout(function () {
				self.changeElementStyle();
			}, 10);
		},
		changeElementStyle: (function () {
			var show = this.get('show');

			if (show) {
				return document.body.classList.add('modal-open');
			}

			document.body.classList.remove('modal-open');
		}).observes('show'),
		didDestroyElement: function didDestroyElement() {
			document.body.classList.remove('modal-open');
		}
	});
});