define('cal-culate-app/services/mealplan-generator', ['exports', 'ember', 'npm:async'], function (exports, _ember, _npmAsync) {
	exports['default'] = _ember['default'].Service.extend({
		loading: _ember['default'].inject.service(),
		store: _ember['default'].inject.service(),
		createMealPlan: function createMealPlan(customer, parentMealPlan, prependText, callback) {
			var self = this;

			this.set('loading.isLoading', true);
			this.set('loading.purpose', 'Erstelle individuellen Ernährungsplan...');

			var phrases = ['Ermittle individuellen Nährwertbedarf...', 'Filtere Lebensmitteldatenbank auf Unverträglichkeiten...', 'Finde passende Rezepte für Nährwertbedarf...', 'Errechne passende Rezeptkombinationen...', 'Skaliere Rezepte auf persönlichen Mengenbedarf...', 'Ergänze Snacks...', 'Suche beste Tagekombinationen...', 'Überprüfe Rechenergebnisse auf Plausibilität...', 'Schreibe Rezepttexte...', 'Überprüfe Rezepte auf Geschmack...', 'Noch etwas abschmecken...', 'Pflücke frische Kräuter...'];

			var phraseIdx = 0;

			var _loop = function () {
				var phrase = phrases[i];

				if (prependText) {
					phrase = prependText + phrase;
				}

				setTimeout(function () {
					self.set('loading.purpose', phrase);
				}, 4000 * i + Math.floor(Math.random() * 2000));
			};

			for (var i = 0; i < phrases.length; i++) {
				_loop();
			}

			var data = {
				customer: customer
			};

			if (parentMealPlan) {
				data.parentMealPlan = parentMealPlan;
			}

			var mealPlan = this.get('store').createRecord('mealplan', data);

			mealPlan.save().then(function () {
				callback(null, mealPlan);
			}, function (error) {
				self.set('loading.isLoading', false);

				if (typeof error.responseJSON === 'object' && typeof error.responseJSON.errors === 'object') {
					if (error.responseJSON.errors[0] === "Throttled") {
						var message = "Ernährungsplan konnte nicht erstellt werden. Grund ist eine Beschränkung der Filiale";

						alert(message);
						callback(message, null);
						return;
					}
				}

				alert('Bei der Berechnung ist ein Fehler aufgetreten.');
				callback('Bei der Berechnung ist ein Fehler aufgetreten.', null);
			});
		},
		createMealPlanBatch: function createMealPlanBatch(customer, total, callback) {
			var self = this;

			total = parseInt(total);

			if (isNaN(total) || total < 1) {
				total = 1;
			}

			var primaryMealPlan = null;

			this.createMealPlan(customer, false, 'Plan 1 von ' + total + ' - ', function (err, mealPlan) {
				primaryMealPlan = mealPlan;

				var tasks = [];

				var childrenMealPlans = [];

				for (var i = 1; i < total; i++) {
					tasks.push(function (taskCallback) {
						self.createMealPlan(customer, primaryMealPlan, 'Plan ' + (childrenMealPlans.length + 2) + ' von ' + total + ' - ', function (err, mealPlan) {
							if (err) {
								console.log(err);
							}

							childrenMealPlans.push(mealPlan);

							taskCallback(null, mealPlan);
						});
					});
				}

				_npmAsync['default'].series(tasks, function (err, results) {
					callback(null, primaryMealPlan);
				});
			});
		}
	});
});