define('cal-culate-app/services/upload', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Service.extend({
		store: _ember['default'].inject.service(),
		authentication: _ember['default'].inject.service(),
		endpoint: _ember['default'].computed('store', function () {
			var host = this.get('store').adapterFor('application').get('host');

			return host + '/files';
		}),
		headers: _ember['default'].computed('authentication.token', function () {
			var token = this.get('authentication.token');

			if (typeof token !== 'string') {
				return {};
			}

			var authorisationString = 'Bearer ' + token;

			return {
				"Authorization": authorisationString
			};
		}),
		upload: function upload(fileBlob, callback) {
			var store = this.get('store');

			var form = new FormData();
			form.append('file', fileBlob);

			_ember['default'].$.ajax({
				type: 'POST',
				url: this.get('endpoint'),
				data: form,
				headers: this.get('headers'),
				processData: false,
				contentType: false
			}).done(function (data) {
				store.findRecord('file', data.id).then(function (file) {
					callback(null, file);
				});
			});
		}
	});
});