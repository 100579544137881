define('cal-culate-app/helpers/perform', ['exports', 'ember-concurrency/helpers/perform'], function (exports, _emberConcurrencyHelpersPerform) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberConcurrencyHelpersPerform['default'];
    }
  });
  Object.defineProperty(exports, 'perform', {
    enumerable: true,
    get: function get() {
      return _emberConcurrencyHelpersPerform.perform;
    }
  });
});