define('cal-culate-app/services/modal', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Service.extend({
		modals: _ember['default'].A([]),
		openModal: function openModal(options) {
			var modal = new _ember['default'].Object(options);

			this.get('modals').addObject(modal);
		},
		closeModal: function closeModal(modal) {
			this.get('modals').removeObject(modal);
		}
	});
});