define('cal-culate-app/components/modal-meal-ingredient-alternatives', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		modalManager: _ember['default'].inject.service('modal'),
		selectedFood: null,
		primaryFood: null,
		init: function init() {
			this._super.apply(this, arguments);

			this.initializeSelection();
			this.initializePrimaryFood();
		},
		initializePrimaryFood: (function () {
			var self = this;
			var primary = this.get('modal.options.mealIngredient.recipeIngredient.primaryFood');

			primary.then(function () {
				self.set('primaryFood', primary.get('content'));
			});
		}).observes('modal.options.mealIngredient.recipeIngredient.primaryFood'),
		initializeSelection: (function () {
			var self = this;
			var selected = this.get('modal.options.mealIngredient.food');

			if (!selected) {
				return;
			}

			selected.then(function () {
				self.set('selectedFood', selected.get('content'));
			});
		}).observes('modal.options.mealIngredient.food'),
		actions: {
			select: function select() {
				var callback = this.get('modal.options.callback');

				if (!callback) {
					return;
				}

				var selected = this.get('selectedFood');

				callback(selected);

				this.get('modalManager').closeModal(this.get('modal'));
			}
		}
	});
});