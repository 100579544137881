define('cal-culate-app/components/food-list', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['food-list'],
		store: _ember['default'].inject.service(),
		availableCategories: _ember['default'].A([]),
		showList: false,
		searchQuery: '',
		selectedFood: _ember['default'].A([]),
		selectedCategories: _ember['default'].A([]),
		foodExceptions: _ember['default'].A([]),
		selectedItems: _ember['default'].computed('selectedFood', 'selectedCategories', {
			get: function get() {
				var mergedList = new _ember['default'].A([]);

				this.get('selectedFood').forEach(function (food) {
					mergedList.addObject(food);
				});

				this.get('selectedCategories').forEach(function (category) {
					mergedList.addObject(category);
				});

				return mergedList;
			},
			set: function set(key, selectedItems) {
				var selectedFood = new _ember['default'].A([]);
				var selectedCategories = new _ember['default'].A([]);

				selectedItems.forEach(function (item) {
					if (item.get('modelName') === 'food') {
						selectedFood.addObject(item);
					}

					if (item.get('modelName') === 'category') {
						selectedCategories.addObject(item);
					}
				});

				this.set('selectedFood', selectedFood);
				this.set('selectedCategories', selectedCategories);
			}
		}),
		init: function init() {
			this._super.apply(this, arguments);

			var self = this;

			var categories = this.get('store').query('category', {});
			var foods = this.get('store').query('food', {});

			foods.then(function () {
				categories.then(function (categoryList) {
					self.set('availableCategories', categoryList);
				});
			});
		},
		foodMatcher: function foodMatcher(food, term) {
			return food.get('name').toLowerCase().indexOf(term.toLowerCase()) !== -1;
		},
		categoryMatcher: function categoryMatcher(category, term) {
			var self = this;

			if (!term.length) {
				return true;
			}

			var matchesQuery = false;

			category.get('foods').forEach(function (food) {
				if (self.foodMatcher(food, term)) {
					matchesQuery = true;
				}
			});

			return matchesQuery || category.get('name').toLowerCase().indexOf(term.toLowerCase()) !== -1;
		},
		categoryCallback: _ember['default'].computed('searchQuery', function () {
			var self = this;

			return function (item) {
				return self.categoryMatcher(item, self.get('searchQuery'));
			};
		}),
		foodCallback: _ember['default'].computed('searchQuery', function () {
			var self = this;

			return function (item) {
				return self.foodMatcher(item, self.get('searchQuery'));
			};
		}),
		actions: {
			select: function select(item) {
				var selectedItems = this.get('selectedItems');

				if (!selectedItems) {
					selectedItems = new _ember['default'].A([]);
				}

				selectedItems.addObject(item);

				this.set('selectedItems', selectedItems);
				this.set('searchQuery', '');
			},
			remove: function remove(item) {
				var selectedItems = this.get('selectedItems');

				if (!selectedItems) {
					selectedItems = new _ember['default'].A([]);
				}

				selectedItems.removeObject(item);

				this.set('selectedItems', selectedItems);
			},
			showList: function showList() {
				var self = this;

				var el = this.get('element');

				this.set('showList', true);

				var leaveHandler = function leaveHandler(event) {
					if (event.target === el || el.contains(event.target)) {
						return;
					}

					self.set('showList', false);
				};

				document.body.addEventListener('click', leaveHandler);
				document.body.addEventListener('click', function (event) {
					console.log(event.target);
					if (event.target === el || el.contains(event.target)) {
						return;
					}

					setTimeout(function () {
						document.body.removeEventListener('click', leaveHandler);
					}, 500);
				});
			},
			hideList: function hideList() {
				this.set('showList', false);
			},
			categoryView: function categoryView() {
				console.log('test');
			}
		}
	});
});