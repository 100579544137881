define('cal-culate-app/components/toggle-button', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['btn', 'btn-primary'],
		classNameBindings: ['active'],
		active: false,
		didRender: function didRender() {
			var self = this;

			var el = this.get('element');

			_ember['default'].$(el).one('click', function () {
				self.set('active', !self.get('active'));

				console.log(self.get('active'));
			});
		}
	});
});