define('cal-culate-app/helpers/cancel-all', ['exports', 'ember-concurrency/helpers/cancel-all'], function (exports, _emberConcurrencyHelpersCancelAll) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberConcurrencyHelpersCancelAll['default'];
    }
  });
  Object.defineProperty(exports, 'cancelAll', {
    enumerable: true,
    get: function get() {
      return _emberConcurrencyHelpersCancelAll.cancelAll;
    }
  });
});