define('cal-culate-app/components/info-popup', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['info-popup'],
    show: false,
    init: function init() {
      var self = this;

      this._super.apply(this, arguments);

      document.body.addEventListener("click", function (event) {
        if (!self.get('element').contains(event.target)) {
          self.set('show', false);
        }
      });
    },
    actions: {
      toggle: function toggle() {
        this.set('show', !this.get('show'));
      }
    }
  });
});