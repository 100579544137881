define('cal-culate-app/controllers/datenschutz', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    content: false,
    contentful: _ember['default'].inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var client = this.get('contentful').get('client');

      client.getEntries({
        content_type: 'content',
        'fields.slug[in]': 'privacy'
      }).then(function (entries) {
        if (typeof entries.items[0] !== 'object') {
          return;
        }

        _this.set('content', entries.items[0]);
      });
    }
  });
});