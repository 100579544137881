define('cal-culate-app/components/user-info', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		authentication: _ember['default'].inject.service(),
		classNames: ['user-info'],
		showDropdown: false,
		actions: {
			toggleDropdown: function toggleDropdown() {
				this.set('showDropdown', !this.get('showDropdown'));
			},
			logout: function logout() {
				this.get('authentication').logout();
			}
		}
	});
});