define('cal-culate-app/components/meal-tile', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		modal: _ember['default'].inject.service(),
		classNames: ['meal-tile'],
		classNameBindings: ['expand'],
		expand: false,
		init: function init() {
			this._super.apply(this, arguments);
		},
		actions: {
			toggleExpand: function toggleExpand() {
				this.set('expand', !this.get('expand'));
			},
			showMealDetails: function showMealDetails() {
				var meal = this.get('meal');

				this.get('modal').openModal({
					type: "meal",
					options: {
						meal: meal
					}
				});
			}
		}
	});
});