define('cal-culate-app/models/integration', ['exports', 'ember-data'], function (exports, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		name: _emberData['default'].attr('string'),
		secret: _emberData['default'].attr('string'),
		tokenSignature: _emberData['default'].attr('string'),
		active: _emberData['default'].attr('boolean'),
		branch: _emberData['default'].belongsTo('branch'),
		brandColor: _emberData['default'].attr('string'),
		privacyUrl: _emberData['default'].attr('string'),
		introText: _emberData['default'].attr('string')
	});
});