define('cal-culate-app/helpers/contentful-rich-text', ['exports', 'ember'], function (exports, _ember) {
	var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

	exports['default'] = _ember['default'].Helper.extend({
		router: _ember['default'].inject.service(),
		getOptions: function getOptions() {
			var self = this;

			var options = {
				renderNode: defaultNodeRenderers
			};

			options.renderNode["embedded-asset-block"] = function (_ref) {
				var fields = _ref.data.target.fields;

				if (typeof fields.file === 'object' && fields.file.contentType.indexOf('video') !== -1) {
					return "<video src='" + fields.file.url + "' controls></video>";
				}

				if (typeof fields.file === 'object' && fields.file.contentType.indexOf('image') !== -1) {
					return '<img src="' + fields.file.url + '" alt="' + fields.description + '"/>';
				}

				return '<div class="download"><a href="' + fields.file.url + '" target="_blank" class="btn btn-secondary"><i class="fa fa-download"></i> ' + fields.title + '</a></div>';
			};

			options.renderNode['entry-hyperlink'] = function (node, next) {
				//const referencedEntry = getEntryWithId(node.data.target.sys.id);
				var url = node.data.target.fields.slug;

				if (node.data.target.sys.contentType.sys.id === 'category') {
					url = self.get('router').urlFor('academy-category', { category_slug: node.data.target.fields.slug });
				}

				if (node.data.target.sys.contentType.sys.id === 'post') {
					url = self.get('router').urlFor('academy-post', { post_slug: node.data.target.fields.slug });
				}

				return '<a href=\'' + url + '\'>' + next(node.content) + '</a>';
			};

			return options;
		},
		compute: function compute(_ref2, hash) {
			var _ref22 = _slicedToArray(_ref2, 1);

			var rawRichTextField = _ref22[0];

			return _ember['default'].String.htmlSafe(documentToHtmlString(rawRichTextField, this.getOptions()));
		}
	});
});