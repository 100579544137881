define('cal-culate-app/app', ['exports', 'ember', 'cal-culate-app/resolver', 'ember-load-initializers', 'cal-culate-app/config/environment', 'npm:popper.js'], function (exports, _ember, _calCulateAppResolver, _emberLoadInitializers, _calCulateAppConfigEnvironment, _npmPopperJs) {

  window.Popper = _npmPopperJs['default'];

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _calCulateAppConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _calCulateAppConfigEnvironment['default'].podModulePrefix,
    Resolver: _calCulateAppResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _calCulateAppConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});