define('cal-culate-app/helpers/local-date', ['exports', 'ember'], function (exports, _ember) {
	exports.localDate = localDate;

	function localDate(params) {
		var date = params[0];

		if (!date || typeof date.toLocaleString !== 'function') {
			return "";
		}

		return date.toLocaleString();
	}

	exports['default'] = _ember['default'].Helper.helper(localDate);
});